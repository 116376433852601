import React, { useEffect, useContext } from 'react'
import { observer } from 'mobx-react-lite'
import useMediaQuery from '@mui/material/useMediaQuery'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { getTheme } from './theme'
import { RootStoreContext } from './store/rootStoreContext'
import { useResize } from './components/helper/useResize'
import ErrorHandler from './components/errorHandler'
import App from './app'

const OuterApp = (props) => {
  const [root] = useContext(RootStoreContext)

  // eslint-disable-next-line no-unused-vars
  let windowSize = useResize() // causes re-render when window size changes

  useEffect(() => {
    root.gameStore.setWindowSize(windowSize)
  }, [windowSize, root.gameStore])

  let theme
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  if (root.gameStore.uiTheme === 'system') {
    theme = getTheme(prefersDarkMode ? 'dark' : 'light')
  } else {
    theme = getTheme(root.gameStore.uiTheme)
  }

  return (
    <ErrorHandler>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </ErrorHandler>
  )
}

export default observer(OuterApp)
