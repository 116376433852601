import { useContext, useEffect } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { RootStoreContext } from '../store/rootStoreContext.js'
import { Link as RouterLink } from 'react-router-dom'
import FeedbackForm from './feedbackForm'
import CheckIcon from '@mui/icons-material/Check'

const PracticeMenu = (props) => {
  const [root] = useContext(RootStoreContext)

  useEffect(() => {
    root.puzzleStore.clearChapterInfo()
  })

  const pageStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    p: 3,
    mt: 7,
  }

  return (
    <Box sx={{ overflowY: 'auto', height: '100%' }}>
      <Box id='practiceMenu' sx={pageStyle}>
        <Typography>
          <b>Thank you</b> for helping test this game. <br />
          <br />
          Please play through the puzzle groups found below. At the end of each
          puzzle, there's a button where you can leave any feedback you may
          have.
          <br />
        </Typography>
        {root.puzzleStore.chapters
          .filter((chap) => !['intro', 'temp', 'daily'].includes(chap.id))
          .map((chap) => (
            <Button
              key={chap.id}
              sx={{ width: '80%', mx: 'auto' }}
              variant='outlined'
              component={RouterLink}
              to={`/${chap.id}`}
            >
              <Typography sx={{ textTransform: 'none', textAlign: 'center' }}>
                {chap.name}
                {chap.isCompleted && (
                  <CheckIcon
                    color='success'
                    sx={{
                      position: 'absolute',
                      ml: 2,
                      pb: '2px',
                    }}
                  />
                )}
                <br /> {chap.remainingPuzzlesLabel}
              </Typography>
            </Button>
          ))}
        <FeedbackForm />
      </Box>
    </Box>
  )
}

export default observer(PracticeMenu)
