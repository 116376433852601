import { useEffect } from 'react'
import { Fade, Typography } from '@mui/material'
import { logEvent, key } from '../service/analytics'

const Attribution = (props) => {
  const attributionStyle = {
    width: '100%',
    color: 'primary.main',
    fontWeight: 600,
    px: '5%',
    mt: 2,
    mb: 1,
    lineHeight: 1.2,
    pointerEvents: props.show ? 'auto' : 'none',
    display: props.show ? 'block' : 'none',
    fontSize: ['1.0rem', '1.2rem', '1.3rem'],
    textAlign: 'center',
    userSelect: 'text',
  }

  function logClick(event) {
    logEvent(key.attrib_link, {
      text: event.target.innerText,
      href: event.target.href,
    })
  }

  useEffect(() => {
    const attribution = document.getElementById('attribution')
    const links = attribution.querySelectorAll('a')
    for (const link of links) {
      link.addEventListener('click', logClick)
    }
  }, [props.html])

  return (
    <Fade in={props.show} timeout={{ enter: 1000, exit: 100 }}>
      <Typography
        id='attribution'
        sx={attributionStyle}
        dangerouslySetInnerHTML={{ __html: props.html }}
      ></Typography>
    </Fade>
  )
}

export default Attribution
