import { observer } from 'mobx-react-lite'
import { useContext } from 'react'
import { Box, Typography } from '@mui/material'
import { RootStoreContext } from '../store/rootStoreContext'
import TapToContinue from './tapToContinue'

const GameSplash = (props) => {
  const [root] = useContext(RootStoreContext)

  const topStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    textAlign: 'center',
    lineHeight: 1,
    gap: [2, 6, 6],
    minHeight: '600px',
    // background: '#0032',
  }

  const chapterStyle = {
    title: { fontSize: '1.8rem', lineHeight: 1, fontWeight: 700 },
    subTitle: { fontSize: '1.2rem', fontWeight: 600 },
  }

  const dailyStyles = {
    date: {
      fontSize: '2.5rem',
      lineHeight: 1,
      fontWeight: 700,
      mt: 1,
      color: 'highlight.main',
      fontFamily: 'Special Elite',
    },
    day: {
      fontSize: '1.5rem',
      lineHeight: 1,
      fontWeight: 600,
      color: 'highlight.main',
      fontFamily: 'Special Elite',
    },
    desc: {
      mt: 1,
      fontSize: '1.5rem',
      fontWeight: 700,
      color: 'highlight.main',
      fontFamily: 'Special Elite',
    },
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100%',
        // background: '#e272',
      }}
    >
      <Box id='gameSplash' sx={topStyle}>
        {root.puzzleStore.chapter.id === 'daily' ? (
          <Box sx={{ lineHeight: 1 }}>
            <Typography sx={dailyStyles.desc}>
              Daily Quota
              <Box component='span' sx={{ display: 'none' }}>
                {' '}
                chosen from
              </Box>
            </Typography>
            <Typography sx={dailyStyles.date}>
              {root.puzzleStore.DisplayDate.dayOfMonth}
            </Typography>
            <Typography sx={dailyStyles.day}>
              {root.puzzleStore.DisplayDate.dayName}
            </Typography>
          </Box>
        ) : (
          <Box sx={{ mb: 2 }}>
            <Typography sx={chapterStyle.title}>
              {root.puzzleStore.chapter.name}
            </Typography>
            <Typography sx={chapterStyle.subTitle}>
              {root.puzzleStore.chapter.remainingPuzzlesLabel}
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            height: '200px',
            // background: '#0b02',
          }}
        >
          <TapToContinue
            action={props.dismissCallback}
            sx={{
              width: '100%',
              textAlign: 'center',
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default observer(GameSplash)
