//todo: so much of this needs to be renamed to clarify if it's for #puzzle or #wordbank

export const wrongMarkVisualStyle = (theme) => {
  return {
    position: 'absolute',
    visibility: 'hidden',
    opacity: 1,
    color: theme.palette.error.main,
    width: '40px',
    height: '40px',
    transformOrigin: 'center',
  }
}

export const dropTargetVisualStyle = (theme) => {
  return {
    position: 'absolute',
    visibility: 'hidden',
    color: theme.palette.highlight.main,
    width: '100px',
    height: '100px',
    transformOrigin: 'center',
    // animation: this is set in code
    opacity: 0,
    '@keyframes targetAnim': {
      '0%': {
        transform: 'scale(1.5)',
        opacity: 0.7,
      },
      '100%': {
        transform: 'scale(0.8)',
        opacity: 0.3,
      },
    },
  }
}

export const puzzleCardStyle = (theme) => {
  return {
    width: '100%',
    p: 1,
    mb: 2,
    borderRadius: '1px',
    backgroundColor: 'quoteCardBackground',
    boxShadow: theme.palette.boxShadowSpec,
  }
}

export const puzzleWordStyles = (theme) => {
  return {
    p: 1,
    '.correct': {
      // color: 'primary.main',
    },
    '.container': {
      display: 'inline-block',
      position: 'relative',
    },
    '.overlay': {
      display: 'inline-block',
      position: 'absolute',
      width: '100%',
      bottom: ['0.6rem', '0.9rem', '1.0rem'],
      fontSize: ['1.5rem', '1.6rem', '1.7rem'],
      color: theme.palette.highlight.main,
      visibility: 'hidden',
    },
    '.overlay.visible': {
      visibility: 'visible',
    },
    '.word.incorrect': {
      textDecorationColor: theme.palette.highlight.main,
      textDecorationStyle: 'solid',
      textDecorationLine: 'underline',
      textDecorationThickness: '1px',
    },
    '.incorrect': {
      // todo: this is used as a signal to drag drop - change it to a property?
    },
    '.word': {
      display: 'inline-block',
      position: 'relative',
      fontWeight: 500,
      fontFamily: 'Times New Roman',
      letterSpacing: '0.0075rem', // hack for safari outline bug
      color: 'primary.main',
    },
    '.punctuation': {
      color: 'primary.main',
      fontWeight: 500,
    },
    '.puzzleSolved': {
      animationName: 'flip',
      animationDuration: '500ms',
      animationTimingFunction: 'cubic-bezier(0.36, 0.07, 0.19, 0.97)',
      anmationDelay: '0.01s',
      animationFillMode: 'both',
    },
    '@keyframes flip': {
      '50.0%': {
        transform: 'rotateX(180deg)',
      },
      '0%, 100.0%': {
        transform: 'rotateX(0deg)',
      },
    },
  }
}
