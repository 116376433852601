import { useContext } from 'react'
import { Box, Button } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { RootStoreContext } from '../store/rootStoreContext.js'
import { Link as RouterLink } from 'react-router-dom'

const DebugPage = (props) => {
  const [root] = useContext(RootStoreContext)

  return (
    <Box>
      <Button
        onClick={() => root.gameStore.setShowTutorial(true)}
        component={RouterLink}
        to='/'
      >
        set show tutorial
      </Button>

      <Box>{root.gameStore.versionLocation}</Box>
    </Box>
  )
}

export default observer(DebugPage)
