import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom/client'
import OuterApp from './outerApp'
import reportWebVitals from './reportWebVitals'
import { RootStoreContextProvider } from './store/rootStoreContext'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <RootStoreContextProvider>
    <OuterApp />
  </RootStoreContextProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
