import React, { useState, useContext } from 'react'
import { Box, Dialog, DialogTitle, Typography, Button } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { RootStoreContext } from '../store/rootStoreContext'
import MissesBar from './missesBar'

const PuzzleFailed = (props) => {
  const [root] = useContext(RootStoreContext)
  const { open, continueAction } = props

  const dialogStyle = {
    minWidth: ['80%', '50%', '50%'],
    py: 2,
  }

  const titleStyle = {
    color: 'wordIncorrect.main',
    fontSize: '1.5rem',
    fontWeight: 800,
    mx: 'auto',
    lineHeight: 1,
  }

  const buttonStyle = {
    width: '50%',
    mx: 'auto',
    my: 1,
  }

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'sm'}>
      <Box sx={dialogStyle}>
        <Box sx={{ display: 'flex', flexDirection: 'column', py: 2 }}>
          <Typography sx={titleStyle}>failed!</Typography>
          <MissesBar sx={{ transform: 'scale(0.7)', pb: 3 }} />
          {root.gameStore.model === 'free' ? (
            <React.Fragment>
              <Button
                sx={buttonStyle}
                variant='outlined'
                onClick={continueAction}
              >
                continue
              </Button>
            </React.Fragment>
          ) : (
            'NYI'
          )}
        </Box>
      </Box>
    </Dialog>
  )
}

export default PuzzleFailed
