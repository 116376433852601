import React, { useState, useEffect, useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { Box, Container } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@emotion/react'
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom'
import { RootStoreContext } from './store/rootStoreContext'
import TopBar from './components/topBar'
import DebugPage from './components/debugPage'
import { setStore } from './service/analytics'
import PlayPage from './components/pages/playPage'
import PracticeMenu from './components/practiceMenu'
import HomePage from './components/pages/homePage'
import SettingsPage from './components/pages/settingsPage'
import HelpPage from './components/pages/helpPage'
import PacksPage from './components/pages/packsPage'
import LabPage from './components/pages/labPage'
import ReviewPage from './components/pages/reviewPage'
import ChromeSplashDialog from './components/chromeSplashDialog'
import { getBrowser, isDevTest } from './lib/utils'
import GenericDialog from './components/genericDialog'

const App = () => {
  const [root] = useContext(RootStoreContext)
  setStore(root) // todo: make this more obvious wtf it is
  const theme = useTheme()

  const sm = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const md = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const bpIndex = sm ? 0 : md ? 1 : 2

  useEffect(() => {
    root.gameStore.setMuiBreakpoint(bpIndex)
  }, [root.gameStore.windowSize, root.gameStore, bpIndex])

  useEffect(() => {
    async function getVersion() {
      await fetch(
        `${process.env.PUBLIC_URL}/version.txt?cache-bust=${Date.now()}`
      )
        .then((r) => {
          root.gameStore.setVersionLocation(r.url)
          return r.text()
        })
        .then((text) => {
          root.gameStore.setVersionInfo(text.substring(0, 30))
        })
    }
    getVersion()
  }, [root.gameStore])

  const containerStyle = {
    height: '100%',
    px: [2, 2, 2],
    background: `${theme.palette.bodyGradient}`,
  }

  if (getBrowser().chrome && window.outerHeight > window.screen.height) {
    return <ChromeSplashDialog />
  }

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Container
        disableGutters
        maxWidth='sm'
        id='container'
        sx={containerStyle}
        onContextMenu={(ev) => {
          ev.preventDefault()
          return false
        }}
      >
        <TopBar />
        <GenericDialog />
        <Box
          id='topLayout'
          sx={{
            position: 'relative',
            height: '100%',
          }}
        >
          <Routes>
            <Route
              path='/'
              element={
                <Navigate
                  to={root.gameStore.showTutorial ? '/intro' : '/home'}
                />
              }
            />{' '}
            <Route path='/home' element={<HomePage />} />
            <Route path='/:chapterId' element={<PlayPage />} />
            <Route path='settings' element={<SettingsPage />} />
            <Route path='help' element={<HelpPage />} />
            <Route path='packs' element={<PacksPage />} />
            <Route path='lab' element={<LabPage />} />
            {isDevTest() && (
              <>
                <Route path='review' element={<ReviewPage />} />
                <Route path='debug' element={<DebugPage />} />
                <Route path='playtest' element={<PracticeMenu />} />
              </>
            )}
            <Route path='*' element={<Navigate to='/home' />} />{' '}
          </Routes>
        </Box>
      </Container>
    </BrowserRouter>
  )
}

export default observer(App)
