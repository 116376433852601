import { createContext, useState } from 'react'
import { RootStore } from './rootStore'
import { configure } from 'mobx'

configure({
  enforceActions: 'always',
  reactionRequiresObservable: true,
  // observableRequiresReaction: true,
  // computedRequiresReaction: true,
  disableErrorBoundaries: false,
})

const store = new RootStore()
const RootStoreContext = createContext()

const RootStoreContextProvider = ({ children }) => {
  const [contextValue, setContextValue] = useState(store)
  return (
    <RootStoreContext.Provider value={[contextValue, setContextValue]}>
      {children}
    </RootStoreContext.Provider>
  )
}

export { RootStoreContext, RootStoreContextProvider, store }
