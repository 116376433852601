import { Box, SvgIcon, Typography } from '@mui/material'

const Starburst = (props) => {
  const {
    sx = {},
    width = '100px',
    height = '100px',
    text = '',
    angle = -15,
  } = props

  const boxStyle = {
    width: width,
    height: height,
    position: 'relative',
    animation: 'starAnim 3s infinite linear',
    '@keyframes starAnim': {
      '0%': {
        transform: `rotateZ(${angle}deg)`,
      },
      '50%': {
        transform: `rotateZ(${angle * 1.5}deg)`,
      },
      '100%': {
        transform: `rotateZ(${angle}deg)`,
      },
    },
  }

  const textStyle = {
    position: 'absolute',
    textAlign: 'center',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    fontWeight: 800,
    lineHeight: 1,
    color: 'highlight.main',
    fontSize: '1rem',
  }

  return (
    <Box sx={[boxStyle, sx]}>
      <SvgIcon
        viewBox='0 0 400 400'
        id='starburst'
        sx={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%,-50%)',
          width: '100%',
          height: '100%',
          stroke: (theme) => theme.palette.highlight.main,
          fill: (theme) => theme.palette.quoteCardBackground,
        }}
      >
        <path
          d='m200.318-.98 35.534 96.013 89.608-50.829-34.495 96.391 101.316 18.662-88.384 51.667 65.617 79.42-100.916-17.233-.785 103.017-66.23-78.069-66.82 78.411-.552-102.376L32.622 291.21l65.383-78.78-88.823-52.188 100.725-18.322L75.41 44.847l88.937 50.71L200.318-.98z'
          strokeWidth={5}
          transform='matrix(.8747 0 0 .87465 24.419 35.79)'
        />
      </SvgIcon>
      <Typography sx={textStyle}>{text}</Typography>
    </Box>
  )
}

export default Starburst
