import Random from 'java-random'

export const puncRegex = /[.;!:?,\u2013]+/

export function isPunctuation(str) {
  return puncRegex.test(str)
}

export function* shuffle(arr, seed = null) {
  const rng = new Random()
  if (seed) {
    rng.setSeed(seed)
  }
  arr = [...arr]
  while (arr.length) yield arr.splice((rng.nextFloat() * arr.length) | 0, 1)[0]
  // usage: console.log([...shuffle(arr)])
}

// export function getRandomInt(min, max) {
//   // todo: use today's date as the seed (y+m+d)
//   min = Math.ceil(min)
//   max = Math.floor(max)
//   return Math.floor(Math.random() * (max - min) + min) // The maximum is exclusive and the minimum is inclusive
// }

export function timeDisplayFromSeconds(sec) {
  const secString = String(Math.floor(sec % 60)).padStart(2, '0')
  const minString = String(Math.floor(sec / 60)).padStart(1, '0')
  return `${minString}:${secString}`
}

export function getPercentile(v, list) {
  // if (v < list[0]) return 0
  // if (v > list[list.length - 1]) return 100
  return 0 // return (v * 100) / list.length
}

export function getBrowser() {
  let userAgentString = navigator.userAgent
  function has(name) {
    return userAgentString.indexOf(name) > -1
  }
  return {
    chrome: has('Chrome'),
    safari: has('Safari') && !has('Chrome'),
    safariMac: has('Safari') && has('Macintosh'),
    firefox: has('Firefox'),
    iphone: has('iPhone'),
    name: userAgentString,
  }
}

export function isDevEnv() {
  return process.env.NODE_ENV === 'development'
}

export const isDevTest = () => {
  const host = window.location.hostname
  return true || host.match('192.168') || host.match('localhost')
}

export const isObject = (value) => {
  return value?.constructor === Object
}

export function oneTimeListener(node, type, callback) {
  node.addEventListener(
    type,
    function listener(e) {
      e.target.removeEventListener(e.type, listener)
      return callback.call(this, e)
    },
    { once: true }
  )
}

export const removeAndAddListener = (el, name, fn) => {
  if (typeof el === 'string') {
    el = document.getElementById(el)
  }
  el.removeEventListener(name, fn)
  el.addEventListener(name, fn)
}

export const removeListener = (el, name, fn) => {
  if (typeof el === 'string') {
    el = document.getElementById(el)
  }
  el.removeEventListener(name, fn)
}

export function addAndRemoveClassWithEndCallback(
  el,
  className,
  eventName,
  endedCallback = null
) {
  el.classList.add(className)
  oneTimeListener(el, eventName, (ev) => {
    el.classList.remove(className)
    if (endedCallback) endedCallback()
  })
}

export const getRect = (el) => {
  const rect = el.getBoundingClientRect()
  const { top, right, bottom, left, width, height, x, y } = rect
  const centerX = rect.x + rect.width / 2
  const centerY = rect.y + rect.height / 2
  const center = { x: centerX, y: centerY }
  return {
    top,
    right,
    bottom,
    left,
    width,
    height,
    x,
    y,
    centerX,
    centerY,
    center,
  }
}

export function clamp(value, min, max) {
  return Math.max(min, Math.min(value, max))
}

export function distanceBetweenPoints(p1, p2) {
  const dx = Math.abs(p2.x - p1.x)
  const dy = Math.abs(p2.y - p1.y)
  return Math.sqrt(dx * dx + dy * dy)
}

export function isPointWithinRect(point, rect) {
  return (
    point.x >= rect.left &&
    point.x <= rect.right &&
    point.y >= rect.top &&
    point.y <= rect.bottom
  )
}

export function getRectDistance(point, rect) {
  const left = rect.x
  const top = rect.y
  const right = rect.x + rect.width
  const bottom = rect.y + rect.height

  const nearestX = clamp(point.x, left, right)
  const nearestY = clamp(point.y, top, bottom)

  const dx = point.x - nearestX
  const dy = point.y - nearestY

  return Math.sqrt(dx * dx + dy * dy)
}

export function areRectsOverlapped(a, b, buffer) {
  const notXOverlap = a.right < b.left - buffer.x || a.left > b.right + buffer.x
  const notYOverlap = a.bottom < b.top - buffer.y || a.top > b.bottom + buffer.y
  return !(notXOverlap || notYOverlap)
}

export function arrayEquals(a, b) {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  )
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
