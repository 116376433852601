import { Box, Typography } from '@mui/material'
import BottomBar from '../bottomBar'
import FeedbackLink from '../feedbackLink'

const HelpPage = (props) => {
  const headingStyle = {
    fontSize: '1.5rem',
    fontFamily: 'Special Elite',
    width: '100%',
    fontWeight: 800,
    textAlign: 'left',
    mt: 2,
  }
  const textStyle = {
    fontSize: 'inherit',
    textAlign: 'left',
  }

  const HeadingText = (props) => {
    return <Typography sx={headingStyle}>{props.children}</Typography>
  }

  const Text = (props) => {
    return <Typography sx={[textStyle, props.sx]}>{props.children}</Typography>
  }

  return (
    <Box
      id='helpPage'
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'start',
        px: [2, 2, 3],
        pt: [8, 10, 12],
        // background: '#0284',
        overflowY: 'auto',
      }}
    >
      <HeadingText>Objective</HeadingText>
      <Text>
        The goal of the game is to fill in the blanks correctly to recreate the
        original quote displayed.
      </Text>
      <HeadingText>Filling in blanks</HeadingText>
      <Typography component='ul' sx={{ my: 0, width: '100%' }}>
        <li>drag the word up to a blank</li>
        <li>tap a blank, then tap a word</li>
        <li>tap a word, then tap a blank</li>
      </Typography>
      <HeadingText>Scoring</HeadingText>
      <Text>
        Your score is based on how quickly you do this and minimizing mistakes.{' '}
        <br />
        If you'd prefer to play without worrying about score, that's fine. You
        can toggle the timer by tapping on it.
      </Text>
      <Text sx={{ mt: 3, width: '100%' }}>
        Questions? Comments? <FeedbackLink linkText='Send us feedback!' />
      </Text>
      <BottomBar />
    </Box>
  )
}

export default HelpPage
