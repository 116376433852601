import { getRect, getRectDistance, isPointWithinRect } from '../../lib/utils'

let selectableWordBankRects = {}
let puzzleRects = {}
let root = null
let maxTouchOffset = 0

export function init(rootStore) {
  if (!root) {
    root = rootStore
  }
}

export function updateWordBankCacheItem(el) {
  const i = root.puzzle.getWordBankItemByElem(el).index
  selectableWordBankRects[i] = el.getBoundingClientRect()
}

export function updateCaches() {
  maxTouchOffset = 5
  rebuildWordBankCache()
  rebuildPuzzleCache()
}

function rebuildWordBankCache() {
  selectableWordBankRects = {}
  for (let i = 0; i < root.puzzle.wordBank.length; i++) {
    const wordBankItem = root.puzzle.wordBank[i]
    selectableWordBankRects[i] = wordBankItem.elemPtr.getBoundingClientRect()
  }
}

function rebuildPuzzleCache() {
  puzzleRects = {}
  for (let i = 0; i < root.puzzle.wordList.length; i++) {
    const puzzleItem = root.puzzle.wordList[i]
    puzzleRects[i] = puzzleItem.elemPtr.getBoundingClientRect()
  }
}

function isPointOverPuzzle(point) {
  const puzzle = document.getElementById('puzzle')
  const puzzleRect = getRect(puzzle)
  puzzleRect.top = 0
  return isPointWithinRect(point, puzzleRect)
}

export function findClosestBlank(point, maxOffset = 30) {
  if (!isPointOverPuzzle(point)) {
    return -1
  }

  let closestIndex = -1
  let closestDistance = 1000000
  for (const rectIndex in puzzleRects) {
    const rectDist = getRectDistance(point, puzzleRects[rectIndex])
    if (
      rectDist < closestDistance &&
      rectDist < maxOffset &&
      !root.puzzle.wordList[rectIndex].isRevealed
    ) {
      closestDistance = rectDist
      closestIndex = rectIndex
    }
  }

  return closestIndex
}

export function findClosestBankWord(point) {
  let closestIndex = -1
  let closestDistance = 1000000
  for (const rectIndex in selectableWordBankRects) {
    const rectDist = getRectDistance(point, selectableWordBankRects[rectIndex])
    if (rectDist < closestDistance && rectDist < maxTouchOffset) {
      closestDistance = rectDist
      closestIndex = rectIndex
    }
  }

  return closestIndex
}
