import { useState } from 'react'
import { TextField, Button, Box, Typography } from '@mui/material'
import { logToDb, key } from '../service/analytics'

const FeedbackForm = (props) => {
  const [text, setText] = useState('')
  const [showAlert, setShowAlert] = useState(false)

  const handleSubmit = (event) => {
    event.preventDefault()
    if (text.length > 0) {
      const params = {
        message: text.trim(),
        ...props.traceData,
      }

      logToDb(key.feedback_form, params)
      setText('')
    }

    setShowAlert(true)
  }

  const inputStyle = {
    userSelect: 'unset',
    WebkitUserSelect: 'unset',
    msUserSelect: 'unset',
    WebkitTouchCallout: 'unset',
    WebkitTextSizeAdjust: 'unset',
    touchAction: 'unset',
    cursor: 'unset',
    WebkitTapHighlightColor: 'unset',
  }

  return (
    <Box sx={{ mt: 0 }}>
      <form onSubmit={handleSubmit}>
        <TextField
          sx={inputStyle}
          fullWidth
          multiline
          rows={3}
          label={'Thanks!'}
          onChange={(ev) => setText(ev.target.value)}
          value={text}
        />
        <Button
          variant='outlined'
          color='primary'
          type='submit'
          sx={{ display: 'inline', mt: '4px' }}
        >
          send
        </Button>
      </form>
      {showAlert && (
        <Typography
          sx={{ position: 'absolute', left: '120px', bottom: '30px' }}
        >
          sent!
        </Typography>
      )}
    </Box>
  )
}

export default FeedbackForm
