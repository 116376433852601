import { makeAutoObservable, observable, runInAction } from 'mobx'
import { makePersistable } from 'mobx-persist-store'
import ShortUniqueId from 'short-unique-id'
import { getBrowser } from '../lib/utils'
import * as sound from '../lib/sound'

const uid = new ShortUniqueId({ length: 10 })

export class GameStore {
  rootStore
  instaSolve = false
  attributionHtml = ''
  showHintNow = false
  versionInfo = ''
  gameVersion = '0'
  versionLocation = ''
  uuid = uid()
  browser = getBrowser()
  purseBalance = 100
  model = 'free' // free, igc
  prefersTimeShown = true

  bottomBarMode = '' // todo: move below here to uiStore
  topBarHeight = 50
  uiTheme = 'system' // dark, light, system
  windowSize = [0, 0]
  showTutorial = true
  showFeedbackQuestions = true
  muiBreakpoint = 0
  isSwapping = false
  isSoundEnabled = true
  bottomBarHeight = ['60px', '70px', '80px']
  genericDialog = null

  constructor(rootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this, {
      genericDialog: observable.shallow,
    })

    makePersistable(this, {
      name: 'gameStore',
      properties: [
        'showTutorial',
        'uuid',
        'prefersTimeShown',
        'isSoundEnabled',
        'uiTheme',
      ],
      storage: window.localStorage,
    })

    sound.setIsSoundEnabled(this.isSoundEnabled)
  }

  setUiTheme(theme) {
    if (!['system', 'dark', 'light'].includes(theme)) {
      throw new Error(`invalid state: ${theme}`)
    }
    this.uiTheme = theme
  }

  setVersionInfo(t) {
    this.versionInfo = t
    this.gameVersion = t.split(' ')[0]
  }

  setVersionLocation(loc) {
    this.versionLocation = loc
  }

  toggleInstaSolve() {
    this.instaSolve = !this.instaSolve
  }

  setAttributionHtml(v) {
    this.attributionHtml = v
  }

  setShowHintNow(val) {
    this.showHintNow = val
  }

  // setHelpOpen(v) {
  //   this.helpOpen = v
  // }

  setBottomBarMode(v) {
    this.bottomBarMode = v
  }

  setShowTutorial(v) {
    this.showTutorial = v
  }

  setWindowSize(size) {
    this.windowSize = size
  }

  setMuiBreakpoint(v) {
    this.muiBreakpoint = v
  }

  setIsSwapping(v) {
    this.isSwapping = v
  }

  setPurseBalance(v) {
    this.purseBalance = v
  }

  showGenericDialog(content, isFullScreen = false) {
    if (content === null) {
      this.genericDialog = null
    } else {
      this.genericDialog = { content, isFullScreen }
    }
  }

  updatePurseBalance(v) {
    if (v > 0) {
      //      sound.play('coins')
    }
    // want at least 5 but no more than 30 iterations
    const iterations = Math.min(30, Math.max(5, v / 5))
    const start = this.purseBalance
    const end = this.purseBalance + v
    const increment = Math.floor(v / iterations)
    let currVal = start
    let counter = 0
    const intervalId = setInterval(() => {
      currVal += increment
      runInAction(() => {
        this.setPurseBalance(currVal)
      })
      counter += 1
      if (counter >= iterations) {
        clearInterval(intervalId)
        this.setPurseBalance(end)
      }
    }, 50)
  }

  setPrefersTimeShown(v) {
    this.prefersTimeShown = v
  }

  setIsSoundEnabled(v) {
    this.isSoundEnabled = v
    sound.setIsSoundEnabled(v)
  }
}
