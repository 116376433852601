import { useContext, useEffect } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { RootStoreContext } from '../../store/rootStoreContext.js'
import { useNavigate } from 'react-router-dom'
import Difficulty from '../difficulty'
import ChapterStatus from '../chapterStatus'
import BottomBar from '../bottomBar'
import { logEvent, key } from '../../service/analytics'

const PacksPage = (props) => {
  const [root] = useContext(RootStoreContext)
  const navigate = useNavigate()

  useEffect(() => {
    root.puzzleStore.clearChapterInfo()
  })

  const pageStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: 2,
    mt: 7,
  }

  const titleStyle = {
    fontFamily: 'Special Elite',
    fontSize: '2rem',
    color: 'primary.main',
    mx: 'auto',
    lineHeight: 1,
    textAlign: 'center',
  }

  const titleSubStyle = {
    fontFamily: 'Special Elite',
    fontSize: '0.8rem',
    color: 'secondary.main',
    mx: 'auto',
    textAlign: 'center',
  }

  const textStyle = {
    textTransform: 'none',
    lineHeight: 1,
  }

  const chapTitleStyle = {
    ...textStyle,
    fontFamily: 'Special Elite',
    fontSize: '1.2rem',
    color: 'highlight.main',
    fontWeight: 800,
  }

  const chapSubTitleStyle = {
    ...textStyle,
    color: 'primary.main',
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: 1.1,
  }

  const onChoice = (chap) => {
    logEvent(key.pack_click, { chapter_id: chap.id, chapter_name: chap.name })
    if (chap.isLocked) {
      root.gameStore.showGenericDialog(
        <Typography variant='h6' sx={{ p: 4 }}>
          Sorry, not available yet.
        </Typography>
      )
    } else {
      navigate(`/${chap.id}`)
    }
  }

  return (
    <Box
      sx={{
        overflowY: 'auto',
        height: `calc(100% - ${
          root.gameStore.bottomBarHeight[root.gameStore.muiBreakpoint]
        })`,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Box id='packsMenu' sx={pageStyle}>
        <Box>
          <Typography sx={titleStyle}>Puzzle Packs</Typography>
          <Typography sx={titleSubStyle}>(new pack every week!)</Typography>
        </Box>
        {root.puzzleStore.chapters
          .filter((chap) => !['intro', 'temp', 'daily'].includes(chap.id))
          .map((chap) => (
            <Button
              key={chap.id}
              sx={{
                width: ['95%', '80%', '80%'],
                mx: 'auto',
                borderRadius: '15px',
                textAlign: 'left',
              }}
              variant='outlined'
              onClick={() => onChoice(chap)}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Box sx={{ flexGrow: 2 }}>
                  <Typography sx={chapTitleStyle}>{chap.name}</Typography>
                  <Typography sx={chapSubTitleStyle}>
                    {chap.subtitle}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'end',
                    position: 'relative',
                    bottom: '2px',
                    // background: '#0033',
                  }}
                >
                  <ChapterStatus
                    isLocked={chap.isLocked}
                    isCompleted={chap.isCompleted}
                    remaining={chap.remainingPuzzlesLabelShort}
                  />
                  <Difficulty
                    sx={{ position: 'relative', bottom: '-2px' }}
                    value={chap.difficulty}
                  />
                </Box>
              </Box>
            </Button>
          ))}
      </Box>
      <BottomBar />
    </Box>
  )
}

export default observer(PacksPage)
