import { IconButton } from '@mui/material'
import { ArrowBackOutlined } from '@mui/icons-material'

const BackArrow = (props) => {
  return (
    <IconButton {...props} color='inherit' onClick={props.onClick}>
      <ArrowBackOutlined sx={{ fontSize: 'inherit' }} />
    </IconButton>
  )
}

export default BackArrow
