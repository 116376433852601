import { createTheme } from '@mui/material/styles'
import bgTexture from './assets/images/transparent-paper-tile.png'

// const darkCardBg = '#192828'
const darkCardBg = '#1f1d30'
const lightCardBg = '#d9d3c2' //'#fff2e3'

export const getTheme = (uiTheme) => {
  const neutralPalette = {}

  const darkPalette = {
    primary: { main: '#cab8b0' },
    secondary: { main: '#ac9f9b', tinted: '#ac9f9b99' },
    highlight: { main: '#FFA552', contrast: '#f00' },

    nearlyInvisible: { main: '#444' },
    error: { main: '#d7294e' },
    link: { main: '#92b4f4' },
    success: { main: '#6aa84f' },
    wordCorrect: { main: '#6aa84f' },
    wordIncorrect: { main: '#ffa8a9' },
    incorrectWordColorName: 'pink',
    correctWordColorName: 'green',
    wordSelected: { main: '#d66161' }, // #ff7374
    transparentBackground: '#3332',
    bodyBackground: '#181818',
    //    bodyGradient: `repeat url(${bgTexture}),linear-gradient(to top, #0004, #000c), linear-gradient(to top, #432083, #007162)`,
    bodyGradient: `repeat url(${bgTexture}),linear-gradient(to top, #0004, #000a), linear-gradient(to top, #0f0c29, #302b63, #24243e)`,
    quoteCardBackground: darkCardBg,
    boxShadowSpec: `0 0 0 0.5px #dac8c022, 0 0 0 10px ${darkCardBg}, 0px 0px 10px 10px rgba(0, 0, 0, 0.5)`,
    mode: 'dark',
  }

  const lightPalette = {
    primary: { main: '#645d5a' },
    secondary: { main: '#837773', tinted: '#83777399' },
    highlight: { main: '#824abb', contrast: '#fff' }, //'#4285f4'

    nearlyInvisible: { main: '#ccc' },
    error: { main: '#d7294e' },
    link: { main: '#92b4f4' },
    success: { main: '#6aa84f' },
    wordCorrect: { main: '#6aa84f' },
    wordIncorrect: { main: '#d71a20' },
    incorrectWordColorName: 'orange',
    correctWordColorName: 'green',
    wordSelected: { main: '#65090c' },
    transparentBackground: '#fff2',
    bodyBackground: '#efefef',
    bodyGradient: `repeat url(${bgTexture}),linear-gradient(to top, #fff0, #fff0), linear-gradient(to top, #DECBA4cc, #3E515100), linear-gradient(to top, #fff, #fff)`,
    quoteCardBackground: lightCardBg,
    boxShadowSpec: `0 0 0 1px #0002, 0 0 0 10px ${lightCardBg}, 0px 0px 15px 15px rgba(100, 100, 111, 0.2)`,
    mode: 'light',
  }

  const selectedPalette = uiTheme === 'dark' ? darkPalette : lightPalette

  return createTheme({
    palette: {
      ...neutralPalette,
      ...selectedPalette,
    },
    typography: {
      fontFamily: 'Nunito',
    },

    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            color: selectedPalette.primary.main,
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            margin: 0,
            height: '100%',
          },
          body: {
            height: '100%',
            overflowX: 'hidden',
            overflowY: 'hidden',
            userSelect: 'none',
            background: selectedPalette.bodyBackground,
          },
          '#root': {
            height: '100%',
          },
          '*': {
            //outline: '1px solid blue', // for debugging
          },
          a: {
            color: selectedPalette.highlight.main,
          },
          'a, a:link, a:visited, a:hover, a:active': {
            textDecoration: 'none',
          },
        },
      },
    },
  })
}
