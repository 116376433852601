import { useContext, useEffect, useState } from 'react'
import { Box, Button, TextField } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { RootStoreContext } from '../../store/rootStoreContext'
import QuoteArea from '../quoteArea'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

const ReviewPage = (props) => {
  const [root] = useContext(RootStoreContext)
  const [puzzId, setPuzzId] = useState('')

  useEffect(() => {
    const fn = async () => {
      await root.puzzleStore.loadChapterById('r')
      await root.puzzleStore.loadDailyPuzzle()
      setTimeout(() => root.puzzle.setState('succeeded'), 1000)
    }
    fn()
  }, [root.puzzleStore])

  useEffect(() => {
    if (root.puzzle) {
      setPuzzId(root.puzzle.id)
    }
  }, [root.puzzle])

  const loaderup = async (plusDays) => {
    if (puzzId.startsWith('d-')) {
      const group = puzzId.match('d-(\\d{8})')
      if (group) {
        const day = dayjs(group[1], 'YYYYMMDD')
        root.puzzleStore.setDailyDate(day.add(plusDays, 'day'))
        await root.puzzleStore.loadDailyPuzzle()
      }
    } else {
      await root.puzzleStore.loadPuzzleById(puzzId)
    }
    if (root.puzzle) {
      setTimeout(() => root.puzzle.setState('succeeded'), 100)
      setPuzzId(root.puzzle?.id)
    } else {
      console.log('failed')
    }
  }

  return (
    <Box
      id='settingsPage'
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'start',
        pt: 12,
        gap: 2,
        mx: 4,
      }}
    >
      {root.puzzle && <QuoteArea />}
      <form
        onSubmit={async (ev) => {
          ev.preventDefault()
          await loaderup(0)
        }}
      >
        <TextField
          variant='outlined'
          value={puzzId}
          size='small'
          onChange={(ev) => {
            setPuzzId(ev.target.value)
          }}
        />
      </form>

      <Button
        variant='outlined'
        onClick={async () => {
          await loaderup(1)
        }}
      >
        next date
      </Button>
    </Box>
  )
}

export default observer(ReviewPage)
