import { makeAutoObservable } from 'mobx'
import { makePersistable } from 'mobx-persist-store'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { Puzzle } from './Puzzle'
import dailiesJson from '../assets/puzzles/dailies.json'
import puzzleJson from '../assets/puzzles/puzzleData.json'
import chapterJson from '../assets/puzzles/chapterData.json'
import { Chapter } from './chapter'
import { isDevTest } from '../lib/utils'

export class PuzzleStore {
  // all of these MUST be initialized, either here or in ctor
  root = null
  puzzles = []
  usedIds = []
  chapter = null //todo: rename chapter/s to pack/s
  chapters = []
  playMode = 0
  dailyDate = dayjs() //dayjs('2023-02-08') dayjs()
  playedDailies = []
  puzzleSource = null

  constructor(rootStore) {
    this.root = rootStore

    makeAutoObservable(this, {
      usedIds: false,
      DisplayDate: false,
    })

    for (const chapData of chapterJson) {
      if (isDevTest() || !chapData.hide) {
        this.addChapter(new Chapter(chapData, this.root))
      }
    }

    makePersistable(this, {
      name: 'puzzleStore',
      properties: ['playedDailies', 'playMode'],
      storage: window.localStorage,
    })

    dayjs.extend(advancedFormat)
  }

  addChapter(c) {
    this.chapters.push(c)
  }

  loadChapterById(id) {
    this.chapter = this.chapters.find((c) => c.id === id)
    this.chapter.prepPlay()
  }

  clearChapterInfo() {
    this.chapter = null
  }

  get displayPuzzleSource() {
    if (this.puzzleSource === 'dailies') {
      return `Daily Quota<br/>${this.dailyDate.format('YYYY-MM-DD')}`
    } else {
      return `${this.chapter.name} - ${this.chapter.cursor + 1} of ${
        this.chapter.sequence.length
      }`
    }
  }

  async loadPuzzleById(id) {
    const isDailies = id.match('^d-')
    this.puzzleSource = isDailies ? 'dailies' : 'default'
    const puzzles = isDailies ? dailiesJson : puzzleJson
    if (puzzles.find((p) => p.id === 'override')) {
      id = 'override'
    }

    const data = puzzles.find((p) => p.id === String(id))
    if (data) {
      this.root.setPuzzle(new Puzzle(data, this.root))
      this.root.puzzle.setState('puzzleLoaded')
    }

    if (id !== 'override' && !id.match('^tut')) {
      await this.root.puzzle.stats.fetchStats(id)
    }
  }

  loadNextPuzzle() {
    if (this.chapter) {
      if (!this.chapter.isCompleted) {
        const puzzId = this.chapter.sequence[this.chapter.cursor]
        if (puzzId === 'r') {
          this.loadRandomPuzzle()
        } else if (puzzId === 'daily') {
          this.refreshDailyDate()
          this.loadDailyPuzzle()
        } else {
          this.loadPuzzleById(puzzId)
        }
      } else {
        this.chapter = null
      }
    } else {
      this.loadRandomPuzzle()
    }
  }

  loadRandomPuzzle() {
    const puzzles = puzzleJson.filter((p) => !p['id'].includes('tut'))
    let id = 0
    do {
      id = puzzles[Math.floor(Math.random() * puzzles.length)]['id']
    } while (this.usedIds.includes(id))
    this.usedIds.push(id)
    this.loadPuzzleById(id)
  }

  loadDailyPuzzle() {
    const dateString = this.getDailyPuzzleDateString()
    this.loadPuzzleById(`d-${dateString}-1`)
  }

  getDailyPuzzleDateString() {
    return this.dailyDate.format('YYYYMMDD')
  }

  get DisplayDate() {
    const dayOfMonth = this.dailyDate.format('MMM Do')
    const dayName = this.dailyDate.format('dddd')
    return { dayOfMonth, dayName }
  }

  setPlayMode(v) {
    this.playMode = v
  }

  setDailyDate(v) {
    this.dailyDate = v
  }

  refreshDailyDate() {
    this.dailyDate = dayjs()
  }

  async clearStoredData() {
    for (const chap of this.chapters) {
      await chap.clearStorage()
    }
  }

  updatePlayedDailies(id) {
    // remove ids more than 2 days old
    this.playedDailies = this.playedDailies.filter((i) => {
      const dateOfPuzz = dayjs(id.replace('d-', '').substring(0, 8))
      return dateOfPuzz >= this.dailyDate.subtract(2, 'day')
    })

    if (!this.playedDailies.includes(id)) {
      this.playedDailies.push(id)
    }
  }
}
