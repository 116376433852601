import { observer } from 'mobx-react-lite'
import { useContext } from 'react'
import { Box, Typography, IconButton } from '@mui/material'
import { EmojiObjectsOutlined, AutorenewOutlined } from '@mui/icons-material'
import { RootStoreContext } from '../store/rootStoreContext'
import DelayedFade from './helper/delayedFade'

const GameMenuItem = (props) => {
  return (
    <IconButton
      edge='end'
      sx={{
        justifyContent: 'end',
        py: 0,
      }}
      onClick={props.action}
      disabled={props.disabled}
    >
      <Typography
        sx={{
          verticalAlign: 'bottom',
          textAlign: 'right',
        }}
      >
        {props.label}&nbsp;
        <Box
          component='span'
          sx={{ position: 'relative', bottom: '-3px', fontSize: '1rem' }}
        >
          {props.icon}
        </Box>
      </Typography>
    </IconButton>
  )
}

const GameMenu = (props) => {
  const [root] = useContext(RootStoreContext)

  const topStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    textAlign: 'center',
    lineHeight: 1,
    gap: [2, 6, 6],
    minHeight: '600px',
    // background: '#0032',
  }

  const showHint = () => {
    root.gameStore.setShowHintNow(true)
  }

  const reshuffle = () => {}

  return (
    <Box
      id='gameMenu'
      sx={{
        position: 'absolute',
        right: '-12px',
        bottom: '6px',
        //        background: '#0503',
      }}
    >
      <DelayedFade>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            lineHeight: 1,
          }}
        >
          <GameMenuItem
            label='hint'
            icon={<EmojiObjectsOutlined />}
            action={showHint}
            disabled={root.puzzle.hintsLeft === 0}
          />
          {/* <GameMenuItem
            label='reshuffle'
            icon={<AutorenewOutlined />}
            action={reshuffle}
          /> */}
        </Box>
      </DelayedFade>
    </Box>
  )
}

export default observer(GameMenu)
