import { observer } from 'mobx-react-lite'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { ShoppingCart } from '@mui/icons-material'
import Coins from './coins'

import TimerBar from './timerBar'
import { RootStoreContext } from '../store/rootStoreContext'
import BackArrow from './backArrow'

const TopBar = (props) => {
  const [root] = useContext(RootStoreContext)
  const navigate = useNavigate()
  const location = useLocation()
  const hasPreviousState = location.key !== 'default'

  const outerBarStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    height: `${root.gameStore.topBarHeight}px`,
    width: '100%',
    zIndex: 2,
  }
  const innerBarStyle = {
    position: 'relative',
    width: '600px',
    maxWidth: '100vw',
    height: '100%',
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    px: 1,
    fontSize: ['1.5rem', '1.7rem', '1.9rem'],
  }

  const fontSizeStyle = { fontSize: ['1.2rem', '1.4rem', '1.6rem'] }

  return (
    <Box id='topBar' sx={outerBarStyle}>
      <Box sx={innerBarStyle}>
        {root.puzzle &&
          root.puzzle.id !== 'tut1' &&
          (root.puzzle.playingState || root.puzzle.succeededState) && (
            <TimerBar />
          )}

        {location.pathname !== '/home' && (
          <BackArrow
            sx={fontSizeStyle}
            onClick={
              root.gameStore.showTutorial
                ? (ev) => {
                    ev.preventDefault()
                    root.gameStore.setShowTutorial(false)
                    navigate('/')
                  }
                : (ev) => {
                    ev.preventDefault()
                    hasPreviousState ? navigate(-1) : navigate('/')
                  }
            }
          />
        )}

        {/* <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Coins sx={fontSizeStyle} />
          <ShoppingCart color='wordIncorrect' sx={fontSizeStyle} />
        </Box>*/}
      </Box>
    </Box>
  )
}

export default observer(TopBar)
