import React, { useContext } from 'react'
import { Box, Dialog, Typography } from '@mui/material'
import { RootStoreContext } from '../store/rootStoreContext'
import { SocialIcon } from 'react-social-icons'
import FeedbackForm from './feedbackForm'

const FeedbackDialog = (props) => {
  const [root] = useContext(RootStoreContext)
  const { open, continueAction } = props

  const dialogStyle = {
    minWidth: ['80%', '50%', '50%'],
    px: 2,
    py: 1,
    backgroundColor: 'quoteCardBackground',
    borderRadius: '5px',
  }

  const titleStyle = {
    fontFamily: 'Special Elite',
    mx: 'auto',
  }
  const textStyle = {
    mx: 'auto',
  }

  const socialBoxStyle = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    px: 1,
    gap: 2,
    justifyContent: 'center',
  }

  const NewTabSocialIcon = (props) => {
    return <SocialIcon target='_blank' {...props} />
  }

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={'sm'}
      onClose={() => {
        continueAction()
      }}
    >
      <Box sx={dialogStyle}>
        <Box sx={{ display: 'flex', flexDirection: 'column', py: 2, gap: 2 }}>
          <Typography variant='h5' sx={titleStyle}>
            Feedback, please!
          </Typography>
          <Typography sx={textStyle}>
            We'd love to hear from you via:
          </Typography>
          <Box sx={socialBoxStyle}>
            <NewTabSocialIcon url='https://twitter.com/QuotaPuzzle' />
            <NewTabSocialIcon url='https://reddit.com/u/quotapuzzle' />
            <NewTabSocialIcon url='mailto:quotapuzzle@gmail.com?subject=Quota feedback' />
          </Box>
          <Typography sx={[textStyle, { mt: 2 }]}>
            Or anonymously here:
          </Typography>
          <FeedbackForm />
        </Box>
      </Box>
    </Dialog>
  )
}

export default FeedbackDialog
