import { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { RootStoreContext } from '../../store/rootStoreContext.js'
import GameBoard from '../gameBoard'
import { logEvent, key } from '../../service/analytics'
import GameSplash from '../gameSplash'
import GameSplashTutorial from '../gameSplashTutorial'
import * as sound from '../../lib/sound'

const PlayPage = (props) => {
  const [root] = useContext(RootStoreContext)
  const [showSplash, setShowSplash] = useState(true)
  const { chapterId } = useParams()

  useEffect(() => {
    // todo: this kind of logic belongs in the models, not here
    root.puzzleStore.loadChapterById(chapterId)
    if (chapterId === 'intro') {
      root.puzzleStore.setPlayMode(0)
    }

    sound.init()
    root.puzzleStore.loadNextPuzzle()
    logEvent(key.chapter_started, { chapterId: chapterId })
    return () => root.setPuzzle(null)
  }, [root, root.puzzleStore, chapterId])

  const dismissSpashFn = () => {
    setShowSplash(false)
  }

  if (!root.puzzle) {
    return <></>
  }

  if (!showSplash) {
    return <GameBoard />
  }

  if (root.puzzle.id === 'tut1') {
    return <GameSplashTutorial dismissCallback={dismissSpashFn} />
  }

  if (
    root.puzzleStore.chapter.id === 'daily' &&
    root.puzzleStore.chapter.cursor === 1 // this hack just means we've completed the first puzzle
  ) {
    return <GameSplash dismissCallback={dismissSpashFn} />
  }

  return <GameBoard />
}

export default observer(PlayPage)
