import { useContext } from 'react'
import { Box, Typography } from '@mui/material'
import { SwipeUp, SouthEast } from '@mui/icons-material'
import { useTheme } from '@emotion/react'
import { observer } from 'mobx-react-lite'
import { RootStoreContext } from '../store/rootStoreContext'
import DelayedFade from './helper/delayedFade'
import { getRect } from '../lib/utils'

const HelpTips = (props) => {
  const [root] = useContext(RootStoreContext)
  const theme = useTheme()

  const vPos = {
    tut1: 0.6,
    tut2: 1,
    tut3: 1.2,
  }

  const sentence = document.getElementById('sentence')
  const sentenceRect = getRect(sentence)
  const tipTop = sentenceRect.centerY * vPos[root.puzzle.id]
  const puzzleRect = getRect(document.getElementById('puzzle'))

  const textStyle = {
    textAlign: 'center',
    color: 'highlight.main',
    borderColor: 'secondary.main',
    fontSize: ['1.2rem', '1.3rem', '1.4rem'],
    borderWidth: 1,
    borderStyle: 'dotted',
    p: 2,
    fontWeight: 500,
    margin: 'auto',
    width: 'fit-content',
  }

  const tut1IconStyle = {
    position: 'fixed',
    top: `${puzzleRect.top + 40}px`,
    left: `${puzzleRect.right - 50}px`,
    fontSize: '2rem',
    animation: 'anim1 2s infinite ease-in-out',
    '@keyframes anim1': {
      '0%': {
        opacity: 0,
        transform: 'translateY(100px)',
      },
      '20%': {
        opacity: 1,
      },
    },
  }

  const tut2IconStyle = {
    position: 'fixed',
    top: `${sentenceRect.bottom - 70}px`,
    left: `${sentenceRect.right - 70}px`,
    fontSize: '2rem',
    animation: 'anim2 2s infinite ease-in-out reverse',
    '@keyframes anim2': {
      '0%': {
        transform: 'scale(1)',
        opacity: 1,
      },
      '50%': {
        transform: 'scale(0.8)',
        opacity: 0.3,
      },
    },
  }

  return (
    <Box
      id='helpTip'
      sx={{ position: 'absolute', top: `${tipTop}px`, width: '100%' }}
    >
      {root.helpTips.tipToShow && (
        <DelayedFade
          appearDelay={100}
          fadeoutAfter={root.helpTips.tipToShow.fadeAfter}
        >
          <Box>
            <Typography sx={textStyle}>
              {root.helpTips.tipToShow.text}
              {root.puzzle.id === 'tut1' && root.puzzle.moves === 0 && (
                <SwipeUp sx={tut1IconStyle} />
              )}
              {root.puzzle.id === 'tut2' && root.puzzle.hintsUsed === 0 && (
                <SouthEast sx={tut2IconStyle} />
              )}
            </Typography>
          </Box>
        </DelayedFade>
      )}
    </Box>
  )
}

export default observer(HelpTips)
