import { useContext } from 'react'
import { Box, Typography } from '@mui/material'
import { RootStoreContext } from '../store/rootStoreContext'
import DelayedFade from './helper/delayedFade'

const PuzzleSource = (props) => {
  const [root] = useContext(RootStoreContext)

  const boxStyle = {
    position: 'absolute',
    left: '-12px',
    bottom: '10px',
    width: '100%',
    textAlign: 'left',
  }

  const textStyle = {
    fontSize: ['1.0rem', '1.1rem', '1.2rem'],
    lineHeight: 1.1,
    fontWeight: 600,
  }

  return (
    <DelayedFade appearDelay={500}>
      <Box id='puzzleSource' sx={boxStyle}>
        <Typography
          sx={textStyle}
          dangerouslySetInnerHTML={{
            __html: root.puzzleStore.displayPuzzleSource,
          }}
        ></Typography>
      </Box>
    </DelayedFade>
  )
}

export default PuzzleSource
