import { Box, Typography } from '@mui/material'
import {
  RadioButtonCheckedOutlined,
  RadioButtonUncheckedOutlined,
} from '@mui/icons-material'

const Dot = (props) => {
  const dotStyle = {
    width: '15px',
    height: '15px',
    verticalAlign: 'middle',
  }

  return props.on ? (
    <RadioButtonCheckedOutlined sx={dotStyle} />
  ) : (
    <RadioButtonUncheckedOutlined sx={dotStyle} />
  )
}

const boxStyle = {
  '& *': { textTranform: 'none' },
}

const difficultWords = {
  0: 'easy',
  1: 'medium',
  2: 'hard',
}

const Difficulty = (props) => {
  return (
    <Box component='span' sx={[boxStyle, props.sx]}>
      <Typography sx={{ textTransform: 'none' }}>
        {difficultWords[props.value]}
      </Typography>
      {/* <Dot on={props.value > 0} />
      <Dot on={props.value > 1} />
      <Dot on={props.value > 2} /> */}
    </Box>
  )
}

export default Difficulty
