import { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { RootStoreContext } from '../store/rootStoreContext'
import QuoteArea from './quoteArea'
import TapToContinue from './tapToContinue'
import SuccessReward from './successReward'
import SuccessRewardTutorial from './successRewardTutorial'
import HelpTips from './helpTips'
import PuzzleFailed from './puzzleFailed'
import PuzzleSource from './puzzleSource'
import GameMenu from './gameMenu'
import RateButton from './rateButton'

const GameBoard = (props) => {
  const [root] = useContext(RootStoreContext)
  const navigate = useNavigate()

  const topEdgeY = root.gameStore.topBarHeight + 10

  const actionOnContinue = (ev) => {
    // todo: move all this into chapter class
    ev.preventDefault()
    if (!root.puzzleStore.chapter.isCompleted) {
      root.puzzleStore.loadNextPuzzle()
    } else {
      if (root.puzzleStore.chapter.id === 'intro') {
        root.gameStore.setShowTutorial(false)
      }
      // setTimeout is a hack to prevent it from invoking
      // homePage buttons with this same click

      setTimeout(() => navigate(root.puzzle.isTutorial ? '/home' : '/packs'), 1)
    }
  }

  return (
    <Box
      id='gameBoard'
      sx={{
        width: '95%',
        left: 'calc(5% / 2)',
        height: `calc(100% - ${topEdgeY}px)`,
        top: `${topEdgeY}px`,
        bottom: 0,
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        overscrollBehavior: 'none',
        // background: '#00e3',
      }}
    >
      <Box
        id='quoteAreaContainer'
        sx={{
          flexGrow: root.puzzle.playingState ? 1 : 0,
        }}
      >
        <QuoteArea />
      </Box>

      {root.puzzle.succeededState && <RateButton />}

      {root.puzzle.playingState && <PuzzleSource />}

      {root.puzzle.playingState && <GameMenu />}

      {root.puzzle.succeededState &&
        (root.puzzle.isTutorial ? (
          <SuccessRewardTutorial />
        ) : (
          <SuccessReward />
        ))}

      {root.puzzle.succeededState && (
        <TapToContinue
          action={actionOnContinue}
          doneLabel='play more now'
          sx={{
            width: '100%',
            textAlign: 'center',
          }}
          showDone={root.puzzleStore.chapter.isCompleted}
        />
      )}
      {root.puzzle.playingState && <HelpTips />}

      <PuzzleFailed
        open={root.puzzle.state === 'failed'}
        continueAction={actionOnContinue}
      />
    </Box>
  )
}

export default observer(GameBoard)
