import { Box, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useContext } from 'react'
import { RootStoreContext } from '../store/rootStoreContext.js'

const MissesBar = (props) => {
  const [root] = useContext(RootStoreContext)
  const score = Math.min(10, root.puzzle?.score)

  const textStyle = {
    color: score ? 'wordIncorrect.main' : 'wordCorrect.main',
    fontSize: ['1.5rem', '1.7rem', '1.9rem'],
    fontWeight: 800,
    textAlign: 'center',
  }

  const nonStrike = {
    color: 'secondary.main',
    opacity: 0.3,
  }

  const strike = {
    color: 'wordIncorrect.main',
  }

  return (
    <Typography id='missesBox' sx={{ ...textStyle, ...props.sx }}>
      {Array.from(Array(root.puzzle.maxMisses))
        .map((_, i) => i)
        .reverse()
        .map((v, i) => (
          <Box
            key={i}
            component='span'
            sx={v < root.puzzle.misses ? strike : nonStrike}
          >
            X{i < root.puzzle.maxMisses - 1 && ' '}
          </Box>
        ))}
    </Typography>
  )
}

export default observer(MissesBar)
