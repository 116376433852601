//https://stackoverflow.com/questions/19014250/rerender-view-on-browser-resize-with-react
import { useState, useEffect, useCallback } from 'react'

export function useResize() {
  const getSize = () => {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    }
  }

  const [size, setSize] = useState(getSize)

  const handleResize = useCallback(() => {
    let ticking = false
    if (!ticking) {
      window.requestAnimationFrame(() => {
        setSize(getSize())
        ticking = false
      })
      ticking = true
    }
  }, [])
  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [handleResize])

  return size
}
