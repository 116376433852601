import { logEvent, key } from '../../service/analytics'
import {
  isDevEnv,
  addAndRemoveClassWithEndCallback,
  getRect,
} from '../../lib/utils'
import * as sound from '../../lib/sound'
import * as dragDrop from './dragDrop'
import * as wordbankDisplay from './wordbankDisplay'
import { store } from '../../store/rootStoreContext'

let container = null // todo: rename this. actual dom parent (#puzzle)
let isGameActive = false
let root = null
let selectedWordBankEl = null
let selectedBlankEl = null
let dropTargetVisual = null
let wrongMarkVisual = null
let dropTargetedBlank = null

async function startGamePlay() {
  container = document.getElementById('puzzle')
  root = store
  buildDomTree()
  root.gameStore.setAttributionHtml(root.puzzle.attribution) //todo: why is formatting.js the one doing this?
  isGameActive = true
  selectedWordBankEl = null
  selectedBlankEl = null
  dropTargetVisual = document.getElementById('dropTargetVisual')
  wrongMarkVisual = document.getElementById('wrongMarkVisual')
  root.gameStore.setIsSwapping(false) // todo: needs to be removed
  logEvent(key.level_start, { level_name: root.puzzle.id })
  wordbankDisplay.init(root, {
    refreshTextPresentation,
  })
  setTimeout(refreshTextPresentation, 200)
  dragDrop.init(root, {
    attemptToPlaceWord,
    wordBankWordTapped,
    puzzleBlankTapped,
    clearSelection,
    getSelectedWordEl,
    getSelectedBlankEl,
    setOverlayVisibility,
    sendWordBackToBank,
    sendWordToBlank,
    updateDropTarget,
  })
}

function ensureInitializedInDevMode() {
  // fix for HMR losing state for this module
  if (!root && isDevEnv()) {
    startGamePlay()
  }
}

function buildDomTree() {
  // due to linebreaking behavior for inline-block elements
  // (which I'm using in order to enable the word swap visuals)
  // the tree structure is funky. When there is punctuation,
  // the previous word and the punctuation character(s) are
  // parented to an intermediate span with 'nowrap' style.
  // these wrapper spans are siblings to the regular word spans.

  // if/when i implement my own line layout/positioning, this
  // weird structure would no longer be necessary as i would
  // have custom line breaking.
  container.innerHTML = ''

  let noWrapSpan = null
  for (let i = root.puzzle.wordList.length - 1; i >= 0; i--) {
    const item = root.puzzle.wordList[i]
    if (item.isPunc) {
      noWrapSpan = document.createElement('span')
      noWrapSpan.style.whiteSpace = 'nowrap'
      const puncSpan = document.createElement('span')
      puncSpan.classList.add('punctuation')
      if (item.origText === '\u2013' /*em dash */) {
        puncSpan.classList.add('leftSpace')
      }
      puncSpan.appendChild(document.createTextNode(item.origText))
      noWrapSpan.appendChild(puncSpan)
      item.elemPtr = puncSpan
    } else {
      const wordSpan = document.createElement('span')
      wordSpan.classList.add('word')
      wordSpan.prepend(document.createTextNode(item.origText))

      const overlaySpan = document
        .getElementById('overlayTemplate')
        .cloneNode(true)
      overlaySpan.classList.add('overlay')

      const wordContainer = document.createElement('span')
      wordContainer.classList.add('container')
      wordContainer.prepend(wordSpan)
      wordContainer.prepend(overlaySpan)

      item.elemPtr = wordSpan
      if (noWrapSpan) {
        noWrapSpan.prepend(wordContainer)
        container.prepend(noWrapSpan)
        noWrapSpan = null
      } else {
        container.prepend(wordContainer)
      }
    }
  }
}

function onWindowChange() {
  refreshTextPresentation()
}

function refreshTextPresentation() {
  ensureInitializedInDevMode()
  setCorrectSpaces()
  restoreCapitalization()
  refreshWordStyles()
  wordbankDisplay.syncWordBank()
  setTimeout(dragDrop.updateCache, 10)
}

function setCorrectSpaces() {
  const spaceWidth = `10px`
  for (let el of root.puzzle.flatElementList) {
    // todo: switch to currList?
    el.style.marginLeft = '0px'
    el.style.marginRight = '0px'
    if (el.classList.contains('leftSpace')) {
      el.style.marginLeft = spaceWidth
    }
  }
  for (let el of container.children) {
    el.style.marginRight = spaceWidth
  }
}

function restoreCapitalization() {
  root.puzzle.wordList.forEach((item) => {
    item.elemPtr.textContent = item.correctlyCapitalized
  })
}

function refreshWordStyles() {
  // punctuation should adopt the color of the word before it
  root.puzzle.wordList.forEach((item) => {
    const showAsCorrect = item.isPunc || item.isRevealed

    item.elemPtr.classList.remove(
      'correct',
      'incorrect',
      'selected',
      'incorrectUnderline'
    )

    item.elemPtr.classList.add(showAsCorrect ? 'correct' : 'incorrect')

    if (!showAsCorrect && !item.isPunc) {
      // we don't want to underline the punctuation
      item.elemPtr.classList.add('incorrectUnderline')
    }

    if (!item.isPunc) {
      const showOverlay =
        (selectedWordBankEl && !showAsCorrect) ||
        selectedBlankEl === item.elemPtr

      setOverlayVisibility(item.elemPtr, showOverlay)
    }
  })
}

function showWrongMark(wordBankEl, blankEl) {
  const containerRect = getRect(container)
  const blankRect = getRect(blankEl)
  const left = blankRect.centerX - containerRect.left - 20 + 8
  const yOffset = [17, 10, 10][root.gameStore.muiBreakpoint]
  const top = blankRect.centerY - containerRect.top - yOffset
  wrongMarkVisual.style.left = `${left}px`
  wrongMarkVisual.style.top = `${top}px`
  wrongMarkVisual.style.visibility = `visible`
  wrongMarkVisual.classList.add('shakeOnIncorrect')
  wordBankEl.classList.add('wrong')
  setTimeout(() => {
    wrongMarkVisual.style.visibility = `hidden`
    wordBankEl.classList.remove('wrong')
    wrongMarkVisual.classList.remove('shakeOnIncorrect')
  }, 1000)
}

function updateDropTarget(blankEl) {
  if (blankEl !== dropTargetedBlank) {
    dropTargetedBlank = blankEl
    if (dropTargetedBlank) {
      const containerRect = getRect(container)
      const blankRect = getRect(blankEl)
      const left = blankRect.centerX - containerRect.left - 50 + 8
      const yOffset = [42, 36, 36][root.gameStore.muiBreakpoint]
      const top = blankRect.centerY - containerRect.top - yOffset
      dropTargetVisual.style.left = `${left}px`
      dropTargetVisual.style.top = `${top}px`
      dropTargetVisual.style.animation = `targetAnim 1s infinite ease-in-out alternate`
      dropTargetVisual.style.visibility = `visible`
    } else {
      dropTargetVisual.style.animation = `none`
      dropTargetVisual.style.visibility = `hidden`
    }
  }
}

function setOverlayVisibility(el, visible) {
  const overlayElement = el.parentElement.querySelector('.overlay')
  if (visible) {
    overlayElement.classList.add('visible')
  } else {
    overlayElement.classList.remove('visible')
  }
}

function getOverlayVisibility(el) {
  const overlayElement = el.parentElement.querySelector('.overlay')
  return overlayElement.classList.contains('visible')
}

function selectPuzzleBlank(thisBlank) {
  const makeSelected = (el) => {
    if (el) {
      setOverlayVisibility(el, true)
      selectedBlankEl = el
    }
  }
  const makeUnselected = (el) => {
    if (el) {
      setOverlayVisibility(el, false)
      selectedBlankEl = null
    }
  }

  if (thisBlank) {
    if (getOverlayVisibility(thisBlank)) {
      makeUnselected(thisBlank)
    } else {
      makeUnselected(selectedBlankEl)
      makeSelected(thisBlank)
    }
  } else {
    makeUnselected(selectedBlankEl)
  }
}

function getSelectedWordEl() {
  return selectedWordBankEl
}

function getSelectedBlankEl() {
  return selectedBlankEl
}

function selectWordBankWord(thisWord) {
  const makeSelected = (el) => {
    if (el) {
      el.classList.add('selected')
      selectedWordBankEl = el
    }
  }
  const makeUnselected = (el) => {
    if (el) {
      el.classList.remove('selected')
      selectedWordBankEl = null
    }
  }

  if (thisWord) {
    if (thisWord.classList.contains('selected')) {
      makeUnselected(thisWord)
    } else {
      makeUnselected(selectedWordBankEl)
      makeSelected(thisWord)
    }
  } else {
    makeUnselected(selectedWordBankEl)
  }
}

function clearSelection() {
  selectWordBankWord(null)
  selectPuzzleBlank(null)
  refreshWordStyles()
}

function advanceBlankSelection(el) {
  const currItem = root.puzzle.getCurrItemByElem(el)
  const nextItem = root.puzzle.getNextIncorrectItem(currItem)
  if (nextItem) {
    selectPuzzleBlank(nextItem.elemPtr)
  } else {
    clearSelection()
  }
}

function puzzleBlankTapped(puzzleEl) {
  let wasCorrect = null
  if (selectedWordBankEl) {
    wasCorrect = attemptToPlaceWord(selectedWordBankEl, puzzleEl)
    if (wasCorrect) {
      clearSelection()
      advanceBlankSelection(puzzleEl)
    } else {
      clearSelection()
    }
  } else {
    selectPuzzleBlank(puzzleEl)
  }
  refreshWordStyles()
  return wasCorrect
}

function wordBankWordTapped(wordBankEl) {
  let wasCorrect = null
  if (selectedBlankEl) {
    wasCorrect = attemptToPlaceWord(wordBankEl, selectedBlankEl)
    if (wasCorrect) {
      advanceBlankSelection(selectedBlankEl)
    } else {
      clearSelection()
    }
  } else {
    selectWordBankWord(wordBankEl)
  }
  refreshWordStyles()
  return wasCorrect
}

function attemptToPlaceWord(wordBankEl, puzzleEl) {
  wordBankEl = wordBankEl || selectedWordBankEl
  const wasCorrect = root.puzzle.placeWord(wordBankEl, puzzleEl)
  sound.play(wasCorrect ? 'successDing' : 'failClick')
  if (wasCorrect) {
    checkForCompletion()
    refreshTextPresentation()
  } else {
    addAndRemoveClassWithEndCallback(
      document.getElementById('puzzle'),
      'shakeOnIncorrect',
      'animationend'
    )
    showWrongMark(wordBankEl, puzzleEl)
  }

  return wasCorrect
}

function showNextHint() {
  if (isGameActive && !root.gameStore.isSwapping) {
    logEvent(key.show_hint)
    sound.play('successDing')
    const [bankItem, blankItem] = root.puzzle.giveHint()
    if (bankItem) {
      sendWordToBlank(bankItem.elemPtr, blankItem.elemPtr, () => {
        clearSelection()
        refreshTextPresentation()
        checkForCompletion()
      })
    }
  }
}

const sendWordBackToBank = (el) => {
  el.style.left = `${el.initialLeft}px`
  el.style.top = `${el.initialTop}px`
  el.style.transform = `translateY(0px)`
  addAndRemoveClassWithEndCallback(
    el,
    'useReturnTransform',
    'transitionend',
    refreshTextPresentation
  )
}

const sendWordToBlank = (el, blankEl, callback) => {
  const wordRect = getRect(el)
  const blankRect = getRect(blankEl)
  const wordbankRect = getRect(document.getElementById('wordbank'))
  el.style.left = `${
    blankRect.centerX - wordbankRect.left - wordRect.width / 2
  }px`
  el.style.top = `${blankRect.top - wordbankRect.top}px`
  el.style.transform = `translateY(0px)`
  addAndRemoveClassWithEndCallback(
    el,
    'useReturnTransform',
    'transitionend',
    callback
  )
}

function checkForCompletion() {
  if (!isGameActive) return
  // wordsToSwap = []
  if (root.gameStore.instaSolve || root.puzzle.isPuzzleSolved) {
    isGameActive = false
    for (let el of root.puzzle.flatElementList) {
      addAndRemoveClassWithEndCallback(el, 'puzzleSolved', 'animationend')
    }

    logCompletedEvent()
    setTimeout(() => {
      root.puzzle.setState('succeeded') // why is this being set by formatting? it should happen within models
    }, 300)
  }
}

// todo: move this to puzzle store
function logCompletedEvent() {
  const params = {
    level_name: root.puzzle.id,
    success: true,
    duration_sec: Math.round(root.puzzle.secondsTaken),
    hints_used: root.puzzle.hintsUsed,
    misses: root.puzzle.misses,
  }

  logEvent(key.level_end, params)
}

export { startGamePlay, showNextHint, onWindowChange }
