import { useContext } from 'react'
import { Box, Typography, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import GameLogo from './gameLogo'
import { RootStoreContext } from '../store/rootStoreContext'

const GameSplashTutorial = (props) => {
  const [root] = useContext(RootStoreContext)

  const navigate = useNavigate()
  const buttonStyle = {
    fontWeight: 600,
    borderRadius: '3px',
    fontSize: ['1.5rem', '1.5rem', '1.5rem'],
    textTransform: 'none',
    color: 'primary.main',
    lineHeight: 1.5,
    my: 1,
    mx: 'auto',
    maxWidth: '200px',
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100%',
        p: 3,
      }}
    >
      <GameLogo />
      <Typography
        variant='h6'
        sx={{ mt: 6, mb: 2, textAlign: 'center', fontWeight: 700 }}
      >
        {/* Welcome! */}
      </Typography>
      <Button
        variant='outlined'
        sx={[buttonStyle, { color: 'highlight.main', fontWeight: 700 }]}
        onClick={props.dismissCallback}
      >
        &nbsp;how to play&nbsp;
      </Button>
      <Button
        variant='outlined'
        sx={buttonStyle}
        onClick={() => {
          root.gameStore.setShowTutorial(false)
          navigate('/')
        }}
      >
        skip tutorial
      </Button>
    </Box>
  )
}

export default GameSplashTutorial
