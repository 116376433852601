import { useContext, Fragment } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { RootStoreContext } from '../../store/rootStoreContext'
import BottomBar from '../bottomBar'
import { isDevTest } from '../../lib/utils'
import { logEvent, key } from '../../service/analytics'
import AboutContent from '../aboutContent'
import CopyrightContent from '../copyrightContent'

const SettingsPage = (props) => {
  const [root] = useContext(RootStoreContext)
  const navigate = useNavigate()

  const Setting = (props) => {
    return (
      <Button
        variant='outlined'
        sx={{ width: '100%' }}
        onClick={() => {
          props.action()
          logEvent(key.settings, { text: props.children.toString() })
        }}
      >
        <Typography variant='h6' style={{ textTransform: 'none' }}>
          {props.children}
        </Typography>
      </Button>
    )
  }

  return (
    <Box
      id='settingsPage'
      sx={{
        overflowY: 'auto',
        height: `calc(100% - ${
          root.gameStore.bottomBarHeight[root.gameStore.muiBreakpoint]
        })`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'start',
        pt: 10,
        gap: 1,
        px: 4,
      }}
    >
      <Typography variant='h4' sx={{ fontFamily: 'Special Elite', mb: 3 }}>
        settings
      </Typography>

      <Setting
        action={() => {
          root.gameStore.setIsSoundEnabled(!root.gameStore.isSoundEnabled)
        }}
      >
        sound: {root.gameStore.isSoundEnabled ? 'on' : 'off'}
      </Setting>

      <Setting
        action={() => {
          root.gameStore.setUiTheme(
            root.gameStore.uiTheme === 'system'
              ? 'light'
              : root.gameStore.uiTheme === 'light'
              ? 'dark'
              : 'system'
          )
        }}
      >
        theme:{' '}
        {root.gameStore.uiTheme === 'system'
          ? 'system'
          : root.gameStore.uiTheme === 'light'
          ? 'light'
          : 'dark'}
      </Setting>

      <Setting
        action={() => {
          root.helpTips.clearData()
          root.gameStore.setPrefersTimeShown(true)
          navigate('/intro')
        }}
      >
        tutorial
      </Setting>

      <Setting
        action={() => root.gameStore.showGenericDialog(<CopyrightContent />)}
      >
        copyright notice
      </Setting>

      {/* <Setting
        action={() => root.gameStore.showGenericDialog(<AboutContent />)}
      >
        about
      </Setting> */}

      <Setting
        action={async () => {
          await root.clearStoredData()
        }}
      >
        clear stored data
      </Setting>

      {isDevTest() && (
        <Fragment>
          <Setting
            action={() =>
              root.puzzleStore.setDailyDate(
                root.puzzleStore.dailyDate.add(1, 'day')
              )
            }
          >
            add a day to daily
          </Setting>
          <Setting
            action={() => {
              root.gameStore.toggleInstaSolve()
            }}
          >
            instaSolve: {root.gameStore.instaSolve ? 'on' : 'off'}
          </Setting>
        </Fragment>
      )}

      <Typography
        sx={{
          position: 'absolute',
          top: '0px',
          textAlign: 'right',
          width: '100%',
          fontSize: '0.8rem',
          mt: 2,
        }}
      >
        v{root.gameStore.gameVersion} {root.gameStore.versionInfo.split(' ')[1]}
      </Typography>
      <BottomBar />
    </Box>
  )
}

export default observer(SettingsPage)
