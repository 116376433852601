import { observer } from 'mobx-react-lite'
import { useContext } from 'react'
import { Box } from '@mui/material'

import { RootStoreContext } from '../store/rootStoreContext'
import BottomBarButton from './bottomBarButton'

const BottomBar = (props) => {
  const [root] = useContext(RootStoreContext)

  const outerBarStyle = {
    position: 'fixed',
    bottom: 0,
    width: root.gameStore.muiBreakpoint >= 2 ? 'default' : '100%',
    height: root.gameStore.bottomBarHeight,
    maxHeight: '100px',
    bgcolor: 'transparentBackground',
    boxShadow: '0 0 5px 5px #0001',
    zIndex: 2,
  }
  const innerBarStyle = {
    position: 'relative',
    width: '600px',
    maxWidth: '100vw',
    height: '100%',
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    gap: [3, 1, 1],
    px: 5,
  }

  return (
    <Box id='bottomBar' sx={outerBarStyle}>
      <Box sx={innerBarStyle}>
        <BottomBarButton path='home' />
        <BottomBarButton path='packs' />
        {/* <BottomBarButton path='lab' /> */}
        <BottomBarButton path='settings' />
        <BottomBarButton path='help' />
      </Box>
    </Box>
  )
}

export default observer(BottomBar)
