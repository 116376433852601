import React, { useEffect, useContext } from 'react'
import { Box, Dialog } from '@mui/material'
import { RootStoreContext } from '../store/rootStoreContext'
import { observer } from 'mobx-react-lite'
import { useLocation } from 'react-router'

const GenericDialog = (props) => {
  const [root] = useContext(RootStoreContext)

  const location = useLocation()

  useEffect(() => {
    if (root.gameStore.genericDialog) {
      close()
    }
  }, [location])

  const boxStyle = {
    maxWidth: '500px',
    px: 2,
    py: 1,
    backgroundColor: 'quoteCardBackground',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }

  const close = () => {
    root.gameStore.showGenericDialog(null)
  }

  return (
    <Dialog
      open={null !== root.gameStore.genericDialog}
      onClose={close}
      onPointerUp={close}
    >
      <Box sx={boxStyle}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {root.gameStore.genericDialog && root.gameStore.genericDialog.content}
        </Box>
      </Box>
    </Dialog>
  )
}

export default observer(GenericDialog)
