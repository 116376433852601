import { Box, Typography } from '@mui/material'

const CopyrightContent = (props) => {
  return (
    <Box>
      <Typography variant='h6'>
        All quotes belong to the originating authors and artists. We claim no
        rights to the quoted text.
      </Typography>
    </Box>
  )
}

export default CopyrightContent
