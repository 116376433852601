// Import the functions you need from the SDKs you need
const url =
  'https://76je3cz5bd.execute-api.us-east-1.amazonaws.com/default/swapTestFunction'

// export const logToDb = async (tag, eventObj) => {
//   try {
//     eventObj.table = 'dynamotest'
//     eventObj.tag = tag
//     const payload = JSON.stringify(eventObj)
//     await fetch(url, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: payload,
//     })
//   } catch (e) {
//     console.error(e)
//   }
// }

export const logErrorEvent = async (eventObj) => {
  try {
    eventObj.table = 'web-errors'

    const payload = JSON.stringify(eventObj)
    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: payload,
    })
  } catch (e) {
    console.error(e)
  }
}
