import React, { useState } from 'react'
import { Link } from '@mui/material'
import FeedbackDialog from './feedbackDialog'

const FeedbackLink = (props) => {
  const { linkText } = props
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false)

  return (
    <>
      <Link
        sx={{ color: 'highlight.main', fontWeight: 600 }}
        onClick={() => setShowFeedbackDialog(true)}
      >
        {linkText}
      </Link>

      <FeedbackDialog
        open={showFeedbackDialog}
        continueAction={() => setShowFeedbackDialog(false)}
      />
    </>
  )
}

export default FeedbackLink
