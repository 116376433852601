import { useState } from 'react'
import { Box, IconButton, Typography, Fade } from '@mui/material'
import { ShareOutlined } from '@mui/icons-material'
import StatsShareDialog from './statsShareDialog'

const StatsShareButton = (props) => {
  const [showDialog, setShowDialog] = useState(false)
  const [showCopiedMessage, setShowCopiedMessage] = useState(false)
  const [messageContent, setMessageContent] = useState('')

  const boxStyle = {
    mx: 'auto',
    mt: 1,
  }
  const shareButtonStyle = {
    color: 'highlight.main',
    pb: 0,
    '& *': { fontSize: '1.7rem' },
    animation: 'sharIconAnim 2s infinite linear',
    '@keyframes sharIconAnim': {
      '0%': {
        transform: 'scale(1)',
      },
      '50%': {
        transform: 'scale(1.2)',
      },
      '100%': {
        transform: 'scale(1)',
      },
    },
  }

  const copiedMessageStyle = {
    color: 'highlight.main',
    fontWeight: 800,
  }

  const onShareClicked = () => {
    setShowDialog(true)
  }

  const dialogClosed = (result) => {
    setShowDialog(false)
    if (result) {
      setMessageContent(
        result === 'succeeded' ? 'copied to clipboard!' : 'copy failed'
      )
      setShowCopiedMessage(true)
      setTimeout(() => setShowCopiedMessage(false), 1000)
    }
  }

  return (
    <Box sx={[boxStyle, props.sx]}>
      <IconButton sx={shareButtonStyle} onClick={onShareClicked}>
        <ShareOutlined />
      </IconButton>
      <Fade in={showCopiedMessage}>
        <Typography sx={copiedMessageStyle}>{messageContent}</Typography>
      </Fade>
      <StatsShareDialog open={showDialog} continueAction={dialogClosed} />
    </Box>
  )
}

export default StatsShareButton
