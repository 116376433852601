import React, { useEffect, useState } from 'react'
import { Box, Dialog, Typography } from '@mui/material'
import GameLogo from './gameLogo'
import { logEvent, key } from '../service/analytics'
import { getBrowser } from '../lib/utils'

const ChromeSplashDialog = (props) => {
  const [open, setOpen] = useState(true)

  useEffect(() => {
    logEvent(key.chromeSplashHack, { ua: getBrowser().name })
  }, [])

  const boxStyle = {
    background: (theme) => `${theme.palette.bodyGradient}`,
    height: '100%',
  }

  const textStyle = {
    mt: 6,
    mx: 'auto',
    fontWeight: 600,
  }

  return (
    <Dialog
      open={open}
      fullScreen
      onClose={() => {
        setOpen(false)
      }}
      onTouchEnd={() => {
        setOpen(false)
      }}
    >
      <Box sx={boxStyle}>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 30 }}>
          <GameLogo />
          <Typography component='h6' sx={textStyle}>
            tap to play
          </Typography>
        </Box>
      </Box>
    </Dialog>
  )
}

export default ChromeSplashDialog
