import { makeAutoObservable, observable, runInAction } from 'mobx'
import { isPunctuation, puncRegex, shuffle } from '../lib/utils'
import { tweaks } from './tweaks'
import { logEvent, key, writeTimeToCloud } from '../service/analytics'
import { PuzzleStats } from './PuzzleStats'

export class Puzzle {
  root = null
  stats = null

  attribution
  id
  lang
  givens
  propnouns
  tags
  text
  ver
  category = ''
  difficulty
  maxHints = 1000 // remove later?
  hintPenalty = 30
  missPenalty = 15
  seed = null
  wordList = null

  //volitile after puzzle is loaded
  state = ''
  secondsTaken = 0
  moves = 0
  hintsUsed = 0
  misses = 0
  maxMisses = 3
  rewards = []
  timerIterationId = []
  startTime = null
  wordBank = []
  penaltySeconds = 0
  knownMisses = {}

  constructor(obj, rootStore) {
    this.root = rootStore
    this.setPuzzleData(obj)
    this.updateHyperlinks()
    this.generateWordList()
    this.setupWordBank()
    this.stats = new PuzzleStats({}, this.root)

    makeAutoObservable(this, {
      timerIterationId: false,
      startTime: false,
      updateVolitileFields: false,
      getCorrectlyCapitalized: false,
      getCurrItemByElem: false,
      rewards: observable.shallow,
      wordBank: observable.shallow,
      wordList: observable.shallow,
      writeTimeToCloud: false,
      getListOfWords: false,
      knownMisses: false,
    })
  }

  setPuzzleData(obj) {
    this.attribution = obj.attribution || ''
    this.id = obj.id
    this.lang = obj.lang || 'en'
    this.propnouns = obj.propnouns || []
    this.tags = obj.tags || []
    this.text = obj.text
    this.ver = obj.ver
    this.difficulty = obj.difficulty || -1
    this.givens = obj.givens || []
    this.seed = obj.seed || 777
  }

  updateHyperlinks() {
    this.attribution = this.attribution.replaceAll(
      '<a href=',
      "<a target='_blank' href="
    )
  }

  getListOfWords() {
    const globalPuncRegex = new RegExp(puncRegex, 'g')
    const txtWithSpaces = this.text.replace(globalPuncRegex, ' $&')
    return txtWithSpaces.split(' ').filter((i) => i)
  }

  generateWordList() {
    this.wordList = this.getListOfWords().map((w, i) => {
      return {
        origIndex: i,
        origText: w,
        lowerText: w.toLowerCase(),
        isPunc: isPunctuation(w),
        elemPtr: null,
        isPropNoun: this.propnouns.includes(i),
        // volatile
        isRevealed: this.givens.includes(i) || isPunctuation(w),
        index: null,
        isValid: null,
        isPreviousItemValid: null,
        isPreviousWordPunc: null,
        correctlyCapitalized: null,
      }
    })

    this.updateVolatileFields()
  }

  setState(state) {
    if (!['puzzleLoaded', 'playing', 'succeeded', 'failed'].includes(state)) {
      throw new Error(`invalid state: ${state}`)
    }

    if (state === 'succeeded') {
      this.updateRewards()
      const elapsedSeconds = (Date.now() - this.startTime) / 1000
      this.setSecondsTaken(elapsedSeconds)
      this.clearStopwatch()
      this.root.puzzleStore.chapter.registerCompletedPuzzle(this.id)
      if (elapsedSeconds > 8) {
        runInAction(async () => {
          await this.writeTimeToCloud(elapsedSeconds)
        })
      }
    }

    if (state === 'playing') {
      setTimeout(() => {
        this.startStopwatch()
      }, 1500)
    }

    if (state === 'failed') {
      const params = {
        duration_sec: Math.round(this.secondsTaken),
        hints_used: this.hintsUsed,
        misses: this.misses,
      }
      logEvent(key.puzzle_failed, params)
    }

    this.state = state
  }

  get playingState() {
    return this.state === 'playing'
  }

  get succeededState() {
    return this.state === 'succeeded'
  }

  get score() {
    return this.misses + this.hintsUsed
  }

  setSecondsTaken(v) {
    this.secondsTaken = v
  }

  async writeTimeToCloud(seconds) {
    await writeTimeToCloud(
      {
        puzzleId: this.id,
        time: this.secondsTaken,
        misses: this.misses,
        hints: this.hintsUsed,
      },
      false
    )
  }

  get formattedTimeTaken() {
    const taken = this.secondsTaken
    return `${Math.floor(taken / 60)}m${Math.round(taken % 60)}s`
  }

  isItemValidAtIndex(item, index) {
    return item.lowerText === this.wordList[index].lowerText
  }

  get flatElementList() {
    return this.wordList.map((item) => item.elemPtr)
  }

  setupWordBank() {
    const wordObjs = this.wordList
      .filter((item) => !item.isPunc && !item.isRevealed)
      .map((item, i) => {
        const text = item.isPropNoun ? item.origText : item.lowerText
        return {
          index: i,
          text,
          lowerText: text.toLowerCase(),
          elemPtr: null,
        }
      })

    this.wordBank = [...shuffle(wordObjs, this.seed)]
  }

  placeWord(wordBankEl, puzzleEl) {
    const bankItem = this.getWordBankItemByElem(wordBankEl) // get bankItem
    const bankText = bankItem.lowerText
    const puzzItem = this.getCurrItemByElem(puzzleEl)
    if (bankText === puzzItem.lowerText) {
      puzzItem.isRevealed = true
      this.wordBank = this.wordBank.filter((i) => i !== bankItem)
      this.updateVolatileFields()
      this.moves += 1
      return true
    } else {
      this.knownMisses[bankText] ??= []
      if (!this.knownMisses[bankText].includes(puzzItem.index)) {
        this.knownMisses[bankText].push(puzzItem.index)
        this.incrementMisses()
      }
      return false
    }
  }

  incrementMisses() {
    this.misses += 1
    this.setPenaltySeconds(this.missPenalty)

    if (
      this.root.puzzleStore.playMode === 1 &&
      this.misses === this.maxMisses
    ) {
      this.setState('failed')
    }
  }

  updateVolatileFields() {
    this.wordList.forEach((item, iter) => {
      item.index = iter
      item.isValid = this.isItemValidAtIndex(item, item.index)

      const prevItem = item.index > 0 ? this.wordList[iter - 1] : null
      item.isPreviousItemValid = prevItem?.isValid
      item.correctlyCapitalized = item.isRevealed
        ? item.origText
        : '\u00a0'.repeat(6) // &nbsp;
    })
  }

  getCorrectlyCapitalized(item, prevItem) {
    // might need this for capitalizing draggable items
    const capitalizeIt =
      item.index === 0 || item.isPropNoun || prevItem.lowerText.match(/[!?.]$/)
    let firstLetter = item.origText[0]
    firstLetter = capitalizeIt
      ? firstLetter.toUpperCase()
      : firstLetter.toLowerCase()
    return firstLetter + item.origText.slice(1)
  }

  getWordBankItemByElem(elem) {
    return this.wordBank.find((item) => elem === item.elemPtr)
  }

  getNextIncorrectItem(currItem) {
    return this.wordList.find(
      (item, i) => i > currItem.index && !item.isRevealed
    )
  }

  getCurrItemByElem(elem) {
    return this.wordList.find((item) => elem === item.elemPtr)
  }

  giveHint() {
    // give next hint in the hint list that is !isRevealed
    // if that yields nothing, pick the next longest unrevealed word
    if (this.wordBank.length === 0 || this.hintsLeft === 0) {
      return
    }

    const itemsTemp = this.wordList
      .filter((i) => !i.isRevealed)
      .sort((a, b) => b.origText.length - a.origText.length)
    const item = itemsTemp[0]

    item.isRevealed = true
    const bankItemToRemove = this.wordBank.find(
      (bankItem) => bankItem.lowerText === item.lowerText
    )
    this.wordBank = this.wordBank.filter(
      (bankItem) => bankItem !== bankItemToRemove
    )

    this.hintsUsed += 1
    this.updateVolatileFields()
    this.setPenaltySeconds(this.hintPenalty)

    return [bankItemToRemove, item]
  }

  get hintsLeft() {
    return Math.min(this.wordBank.length, this.maxHints - this.hintsUsed)
  }

  get isPuzzleSolved() {
    for (const i of this.wordList) {
      if (!i.isRevealed) {
        return false
      }
    }
    return true
  }

  // obsolete
  updateRewards() {
    const rewards = []

    rewards.push({
      text: 'words bonus',
      amount: this.wordList.length * tweaks.perWordBonus,
    })
    if (this.misses === 0) {
      rewards.push({ text: 'no misses', amount: tweaks.noMissesBonus })
    }
    if (this.secondsTaken < 15) {
      rewards.push({ text: 'fast fingers', amount: tweaks.fastFingersBonus })
    }
    this.rewards = rewards

    const total = rewards.reduce((prev, curr) => prev + curr.amount, 0)
    this.root.gameStore.updatePurseBalance(total)
  }

  startStopwatch() {
    this.clearStopwatch()
    this.startTime = Date.now()
    this.root.helpTips.startTracking()
    setTimeout(() => {
      this.timerIterationId.push(
        setInterval(() => {
          this.setSecondsTaken((Date.now() - this.startTime) / 1000)
        }, 1000)
      )
    }, 250) // why?
  }

  clearStopwatch() {
    this.timerIterationId.forEach((n) => clearInterval(n))
    this.timerIterationId = []
    this.root.helpTips.stopTracking()
  }

  setIsWordSelected(v) {
    this.isWordSelected = v
    if (v) {
      this.root.helpTips.registerSelection()
    }
  }

  setPenaltySeconds(s) {
    this.startTime -= s * 1000
    this.penaltySeconds = s
    setTimeout(() => {
      runInAction(() => {
        this.penaltySeconds = 0
      })
    }, 1100)
  }

  get isTutorial() {
    return this.id.match('^tut') !== null
  }
} // end of class
