export const quoteAreaShakeStyle = {
  '@keyframes shake': {
    '10%, 90%': {
      transform: 'translate(-3px)',
    },
    '20%, 80%': {
      transform: 'translate(4px)',
    },
    '30%, 50%, 70%': {
      transform: 'translate(-6px)',
    },
    '40%, 60%': {
      transform: 'translate(6px)',
    },
    '100%': {
      transform: 'translate(0pxs)',
    },
  },
  '.shakeOnIncorrect': {
    animationName: 'shake',
    animationDuration: '700ms',
    animationTimingFunction: 'cubic-bezier(0.36, 0.07, 0.19, 0.97)',
    animationFillMode: 'both',
  },
}
