import { useContext } from 'react'
import { Box, Typography, IconButton } from '@mui/material'
import { VisibilityOutlined } from '@mui/icons-material'
import { observer } from 'mobx-react-lite'
import { RootStoreContext } from '../store/rootStoreContext'
import DelayedFade from './helper/delayedFade'
import DistributionChart from './distributionChart'
import { timeDisplayFromSeconds } from '../lib/utils'
import StatsShareButton from './statsShareButton'

const SuccessReward = (props) => {
  const [root] = useContext(RootStoreContext)

  const boxStyle = { width: '100%', textAlign: 'center' }

  const titleTextStyle = {
    fontSize: ['1.3rem', '1.4rem', '1.5rem'],
    fontWeight: 800,
    color: 'highlight.main',
    lineHeight: 1.2,
  }

  const stats = {
    color: 'secondary.main',
    fontSize: ['1.0rem', '1.1rem', '1.2rem'],
    fontWeight: 700,
    lineHeight: 1,
  }
  const statsBold = {
    color: 'highlight.main',
    fontSize: ['1.0rem', '1.1rem', '1.2rem'],
    fontWeight: 800,
  }

  // todo: move the complements into puzzle.js
  return (
    <DelayedFade>
      <Box id='successReward' sx={boxStyle}>
        <Typography sx={titleTextStyle}>completed!</Typography>
        {root.gameStore.prefersTimeShown ? (
          <Box>
            <Typography sx={stats}>
              your time{' '}
              <Box component='span' sx={statsBold}>
                {timeDisplayFromSeconds(Math.floor(root.puzzle.secondsTaken))}
              </Box>
              {/* <br />
              top{' '}
              <Box component='span' sx={statsBold}>
                {root.puzzle.stats.communityRank}%
              </Box> */}
            </Typography>

            {root.puzzle.stats.data ? (
              <>
                <DistributionChart />
                <StatsShareButton />
              </>
            ) : (
              <Typography sx={{ mt: 1, lineHeight: 1.2 }}>
                We're still gathering community results....
                <br />
                (Ask your friends to play!)
              </Typography>
            )}
          </Box>
        ) : (
          <Box>
            <IconButton
              color='inherit'
              variant='outlined'
              onClick={() => root.gameStore.setPrefersTimeShown(true)}
            >
              <Typography sx={stats}>
                show community rank&nbsp;
                <VisibilityOutlined
                  sx={{ fontSize: '1.5rem', verticalAlign: 'middle' }}
                />
              </Typography>
            </IconButton>
          </Box>
        )}
      </Box>
    </DelayedFade>
  )
}

export default observer(SuccessReward)
