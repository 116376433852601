import { makeAutoObservable, runInAction } from 'mobx'
import { getStatsDoc } from '../service/firebase'
import { clamp } from '../lib/utils'

export class PuzzleStats {
  root = null
  data = null
  cachedPlayerBarId = null

  constructor(obj, rootStore) {
    this.root = rootStore
    makeAutoObservable(this, {
      loadData: false,
      cachedPlayerBarId: false,
    })
  }

  async fetchStats(puzzleId) {
    const result = await getStatsDoc(puzzleId)
    switch (result) {
      case 'not found':
        console.log('stats not found')
        break
      case 'error':
        console.log('stats dl error')
        break
      default:
        runInAction(() => {
          this.loadData(result)
        })
    }
  }

  loadData(result) {
    this.data = result
  }

  get communityRankLabel() {
    return `top ${this.communityRank.finalRank}%`
  }

  get communityRank() {
    const playerTime = this.root.puzzle.secondsTaken
    const shareSum = this.data.bars.reduce((a, c) => a + c.share, 0)
    let playerShareUnits = 0
    let ratioWithinBar = 1
    for (const bar of this.data.bars) {
      if (playerTime >= bar.end) {
        playerShareUnits += bar.share
      } else {
        ratioWithinBar =
          (Math.max(bar.start, playerTime) - bar.start) / (bar.end - bar.start)
        playerShareUnits += Math.floor(bar.share * ratioWithinBar)
        break
      }
    }

    const finalRank = clamp(
      Math.floor((100 * playerShareUnits) / shareSum),
      1,
      100
    )
    return { finalRank, ratioWithinBar }
  }

  get playerBarId() {
    if (this.cachedPlayerBarId) {
      return this.cachedPlayerBarId
    }

    const playerTime = this.root.puzzle.secondsTaken
    let i
    for (i = 0; i < this.data.bars.length; i++) {
      if (playerTime < this.data.bars[i].end) {
        break
      }
    }

    const playerBarId = i >= this.data.bars.length ? i - 1 : i
    this.cachedPlayerBarId = playerBarId
    return playerBarId
  }

  get isTopHalf() {
    return this.playerBarId <= this.data.bars.length / 2
  }
}
