import React, { useState } from 'react'
// import { observer } from 'mobx-react-lite'
import { Box, Dialog, Typography, Rating, styled } from '@mui/material'
import { Favorite, FavoriteBorder } from '@mui/icons-material'
import { writeRatingToCloud } from '../service/analytics'

const RateDialog = (props) => {
  const { open, continueAction } = props
  const [value, setValue] = useState(0)

  const boxStyle = {
    minWidth: ['80%', '50%', '50%'],
    px: 2,
    py: 1,
    backgroundColor: 'quoteCardBackground',
    borderRadius: '5px',
  }

  const textStyle = {
    mx: 'auto',
    fontWeight: 600,
  }

  const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: '#ff6d75',
    },
    '& .MuiRating-iconHover': {
      color: '#ff3d47',
    },
  })

  const sendRating = async () => {
    if (value > 0) {
      await writeRatingToCloud({ rating: value })
    }
  }

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={'sm'}
      onClose={() => {
        sendRating()
        continueAction()
      }}
      sx={{
        '& .MuiDialog-container': {
          alignItems: 'flex-start',
          pt: 10,
        },
      }}
    >
      <Box sx={boxStyle}>
        <Box sx={{ display: 'flex', flexDirection: 'column', py: 2 }}>
          <Typography sx={textStyle}>please rate the puzzle</Typography>
          <StyledRating
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue)
            }}
            icon={<Favorite fontSize='inherit' />}
            emptyIcon={<FavoriteBorder fontSize='inherit' />}
            sx={{ mx: 'auto' }}
          />
        </Box>
      </Box>
    </Dialog>
  )
}

export default RateDialog
