import { Button, Box } from '@mui/material'
import DelayedFade from './helper/delayedFade'

const TapToContinue = (props) => {
  const { action, nextLabel = 'continue', doneLabel = 'done' } = props

  const boxStyle = {}
  const textStyle = {
    fontSize: ['1.3rem', '1.4rem', '1.5rem'],
    mb: 5,
    fontWeight: 600,
    cursor: 'pointer',
    textTransform: 'none',
  }

  return (
    <DelayedFade fadeSpeed={500}>
      <Box
        id='tapToContinue'
        sx={{ ...boxStyle, ...props.sx }}
        onPointerUp={(event) => action(event)}
      >
        <Button variant='outlined' sx={textStyle}>
          {props.showDone ? doneLabel : nextLabel}
        </Button>
      </Box>
    </DelayedFade>
  )
}

export default TapToContinue
