import { useState } from 'react'
import { Box, IconButton } from '@mui/material'
import { StarOutlineOutlined } from '@mui/icons-material'
import RateDialog from './rateDialog'

const RateButton = (props) => {
  const [showDialog, setShowDialog] = useState(false)

  const boxStyle = {
    position: 'absolute',
    top: ['-3px', '5px', '5px'],
    right: '-3px',
  }

  const shareButtonStyle = {
    color: 'highlight.main',
    pb: 0,
    '& *': { fontSize: '1.3rem' },
    animation: 'sharIconAnim 2s infinite linear',
    '@keyframes sharIconAnim': {
      '0%': {
        transform: 'scale(1)',
      },
      '50%': {
        transform: 'scale(1.1)',
      },
      '100%': {
        transform: 'scale(1)',
      },
    },
  }

  const onShareClicked = () => {
    setShowDialog(true)
  }

  const dialogClosed = () => {
    setShowDialog(false)
  }

  return (
    <Box sx={[boxStyle, props.sx]}>
      <IconButton sx={shareButtonStyle} onClick={onShareClicked}>
        <StarOutlineOutlined />
      </IconButton>
      <RateDialog open={showDialog} continueAction={dialogClosed} />
    </Box>
  )
}

export default RateButton
