import { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Box, Fade } from '@mui/material'
import {
  startGamePlay,
  showNextHint,
  onWindowChange,
} from './gameplay/formatting'
import {
  ModeStandbyOutlined,
  CircleOutlined,
  CloseOutlined,
} from '@mui/icons-material'
import { RootStoreContext } from '../store/rootStoreContext'
import {
  puzzleCardStyle,
  puzzleWordStyles,
  dropTargetVisualStyle,
  wrongMarkVisualStyle,
} from './quoteAreaStyle'
import { quoteAreaShakeStyle } from './quoteAreaShakeStyle'
import Attribution from './attribution'

const QuoteArea = (props) => {
  const { appearDelay = 250 } = props
  const [root] = useContext(RootStoreContext)
  const [showSentence, setShowSentence] = useState(false)

  useEffect(() => {
    async function initPuzzle() {
      await startGamePlay()
      root.puzzle.setState('playing')
      setTimeout(() => setShowSentence(true), appearDelay)
    }

    if (root.puzzle.state === 'puzzleLoaded') {
      setShowSentence(false)
      setTimeout(() => initPuzzle(), 100)
    }
  }, [root, root.puzzle.state, appearDelay])

  useEffect(() => {
    if (root.gameStore.showHintNow) {
      root.gameStore.setShowHintNow(false)
      showNextHint()
    }
  }, [root.gameStore, root.gameStore.showHintNow])

  useEffect(() => {
    if (root.puzzle.playingState) {
      onWindowChange()
    }
  }, [root.puzzle.state, root.gameStore.windowSize])

  const sentenceStyle = {
    width: '100%',
    minWidth: '300px',
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: ['1.6rem', '2.0rem', '2.1rem'],
    pt: [0, 1, 1],
    pb: [1, 2, 2],
    lineHeight: root.puzzle.playingState ? [1.3, 1.4, 1.5] : 1.2,
    userSelect: 'none',
    WebkitUserSelect: 'none',
    msUserSelect: 'none',
    WebkitTouchCallout: 'none',
    WebkitTextSizeAdjust: 'none',
    touchAction: 'manipulation' /* blocks double tap zoom */,
    textAlign: 'center',
    cursor: 'pointer',
    WebkitTapHighlightColor: 'transparent',
    // background: '#e002',
  }

  const wordBankStyle = {
    flexGrow: 2,
    mt: 2,
    display: root.puzzle.playingState ? 'block' : 'none',
    position: 'relative',
    width: 'calc(100% + 32px)',
    color: 'primary.main',
    fontWeight: 500,
    fontFamily: 'Times New Roman',
    // background: '#0e02',

    '.selected': {
      fontWeight: 800,
      color: 'highlight.main',
    },
    '.draggable': {
      touchAction: 'none',
      userSelect: 'none',
      px: 1,
      py: 1,
      textAlign: 'center',
      lineHeight: 1.5,
      zIndex: 100,
      // background: '#e002',
    },
    '.wrong': {
      color: 'error.main',
    },
    '.useReturnTransform': {
      transition: 'left 200ms ease-in-out, top 200ms ease-in-out',
    },
    '.useDraggingTransform': {
      transition: 'transform 100ms linear',
    },
    '.firstAppearancePositionTransition': {
      transition: 'left 1000ms ease-out, top 1000ms ease-out',
    },
  }

  return (
    <Fade
      in={showSentence}
      appear={true}
      timeout={{ enter: appearDelay, exit: 0 }}
    >
      <Box id='sentence' sx={{ ...sentenceStyle, ...quoteAreaShakeStyle }}>
        <CircleOutlined id='dropTargetVisual' sx={dropTargetVisualStyle} />
        <CloseOutlined id='wrongMarkVisual' sx={wrongMarkVisualStyle} />
        <Box component='div' id='card' sx={puzzleCardStyle}>
          <Box component='div' id='puzzle' sx={puzzleWordStyles}></Box>
          <Attribution
            show={root.puzzle.state === 'succeeded'}
            html={
              root.puzzle.state === 'succeeded'
                ? root.gameStore.attributionHtml
                : ''
            }
          />
        </Box>
        <Box id='wordbank' sx={wordBankStyle}></Box>
        <ModeStandbyOutlined
          id='overlayTemplate'
          sx={{ position: 'absolute', visibility: 'hidden' }}
        />
      </Box>
    </Fade>
  )
}

export default observer(QuoteArea)
