import { makeAutoObservable, runInAction } from 'mobx'
import { makePersistable } from 'mobx-persist-store'

export class HelpTips {
  root = null
  timeOfLastSelect = 0

  showTooltip1 = false
  shownTips = []
  tipToShow = null

  intervalId = 0
  rules = null

  constructor(rootStore) {
    this.root = rootStore

    makeAutoObservable(this, {
      rules: false,
      intervalId: false,
    })

    this.setRules()
  }

  startTracking() {
    this.timeOfLastSelect = Date.now()
    this.intervalId = setInterval(() => {
      runInAction(() => {
        this.evaluateRules()
      })
    }, 50)

    if (window.location.hostname === 'localhost') {
      this.clearData()
    }
  }

  stopTracking() {
    clearInterval(this.intervalId)
    this.tipToShow = null
  }

  registerSelection() {
    this.timeOfLastSelect = Date.now()
  }

  clearData() {
    this.shownTips = []
  }

  setRules() {
    this.rules = [
      {
        id: 'swap',
        text: "Drag 'be' and 'happy' to fill in the blanks.",
        puzzle: 'tut1',
        triggerTime: 0,
      },
      {
        id: 'stuck',
        text: 'If you ever get stuck, you can use a hint.',
        puzzle: 'tut2',
        triggerTime: 500,
      },
      {
        id: 'recognize',
        text: "Don't worry if you don't recognize the quote. In fact, it's better that way!",
        puzzle: 'tut3',
        triggerTime: 500,
      },
    ]
  }

  evaluateRules() {
    if (!this.root.puzzle) {
      return
    }

    for (const r of this.rules) {
      if (this.shownTips.includes(r.id)) {
        continue
      }
      if (r.puzzle && r.puzzle !== this.root.puzzle.id) {
        continue
      }
      if (r.triggerTime && r.triggerTime > Date.now() - this.timeOfLastSelect) {
        continue
      }
      if (r.id === 'misses' && this.root.puzzle.misses !== 1) {
        continue
      }

      this.tipToShow = r
      this.shownTips.push(r.id)
      return
    }
  }
}
