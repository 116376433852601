import { ErrorBoundary } from 'react-error-boundary'
import { Box } from '@mui/material'
import { logAppError } from '../service/analytics'

window.onerror = (event, source, lineno, colno, error) => {
  // error is { message, stack }, both strings
  handleError(error.message, error.stack)
}

const ErrorFallback = (error, resetErrorBoundary) => {
  return (
    <Box>
      Sorry, there was an error.
      <br />
      <button click={resetErrorBoundary}>return home</button>
    </Box>
  )
}

const handleError = (error, info) => {
  const errorObj = { error, info }
  logAppError(errorObj)
}

const ErrorHandler = (props) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
      {props.children}
    </ErrorBoundary>
  )
}

export default ErrorHandler
