import { useContext } from 'react'
import { Box, Typography } from '@mui/material'
import { SentimentSatisfiedAlt } from '@mui/icons-material'
import { RootStoreContext } from '../store/rootStoreContext'
import DelayedFade from './helper/delayedFade'
import { Switch, Case } from './helper/switch'
import { timeDisplayFromSeconds } from '../lib/utils'
import ClappingHands from './clappingHands'

const SuccessRewardTutorial = (props) => {
  const [root] = useContext(RootStoreContext)

  const boxStyle = { width: '100%', textAlign: 'center' }

  const titleTextStyle = {
    fontSize: ['1.6rem', '1.8rem', '2.0rem'],
    fontWeight: 800,
    color: 'highlight.main',
    lineHeight: 1.2,
    mb: 2,
  }

  const subTextStyle = {
    fontSize: ['1.1rem', '1.3rem', '1.5rem'],
    fontWeight: 600,
    color: 'secondary.main',
    lineHeight: 1.1,
    mb: 1,
  }

  const complimentTitle = {
    tut1: (
      <Box component='span'>
        That was easy!{' '}
        <SentimentSatisfiedAlt
          sx={{
            position: 'relative',
            top: '2px',
            fontSize: ['1.2rem', '1.6rem', '1.6rem'],
          }}
        />
      </Box>
    ),
    tut2: 'Nice job!',
    tut3: (
      <Box component='span'>
        <ClappingHands
          sx={{
            position: 'relative',
            top: '30px',
            width: '100px',
            height: '100px',
          }}
        />
      </Box>
    ),
  }

  return (
    <DelayedFade>
      <Box id='successReward' sx={boxStyle}>
        <Typography sx={titleTextStyle}>
          {complimentTitle[root.puzzle.id]}
        </Typography>
        <Switch test={root.puzzle.id}>
          <Case value='tut1'>
            <Typography sx={subTextStyle}>Let's try another....</Typography>
          </Case>
          <Case value='tut2'>
            <Typography sx={subTextStyle}>
              Your time was{' '}
              <Box
                component='span'
                sx={{ color: 'highlight.main', fontWeight: 800 }}
              >
                {timeDisplayFromSeconds(Math.floor(root.puzzle.secondsTaken))}
              </Box>
              . Time gets added for any misses or hints. <br />
              <br />
              If you'd rather play without scoring, tap the timer to toggle it.
            </Typography>
          </Case>
          <Case value='tut3'>
            <Typography sx={subTextStyle}>
              Training's over, you've got it!
            </Typography>
          </Case>
        </Switch>
      </Box>
    </DelayedFade>
  )
}

export default SuccessRewardTutorial
