import { useTheme } from '@emotion/react'
import { Box, Typography } from '@mui/material'

const GameLogo = (props) => {
  const theme = useTheme()

  const cardStyle = {
    p: [2, 4, 4],
    borderRadius: '3px',
    backgroundColor: 'quoteCardBackground',
    boxShadow: theme.palette.boxShadowSpec,
    width: ['300px', '360px', '360px'],
    mx: 'auto',
    position: 'relative',
  }

  const appTitleStyle = {
    color: 'highlight.main',
    fontFamily: 'Special Elite',
    fontSize: ['5rem', '6rem', '6rem'],
    fontWeight: 400,
    lineHeight: 1,
  }

  const appSubtitleStyle = {
    color: 'primary.main',
    fontSize: ['1.1rem', '1.3rem', '1.3rem'],
    fontFamily: 'Special Elite',
    fontWeight: 400,
    lineHeight: 0.9,
    textAlign: 'start',
    position: 'relative',
    width: 'max-content',
    left: ['82px', '85px', '90px'],
    top: ['-25px', '-23px', '-25px'],
    // background: '#ff37',
  }

  const versionStyle = {
    color: 'primary.main',
    fontSize: ['0.8rem', '1.1rem', '1.1rem'],
    // fontFamily: 'Special Elite',
    fontWeight: 400,
    lineHeight: 0.9,
    textAlign: 'end',
    position: 'absolute',
    right: '3px',
    bottom: '3px',
    opacity: 0.5,
  }

  return (
    <Box sx={cardStyle}>
      <Typography sx={appTitleStyle}>
        <Typography
          component='span'
          sx={{
            ...appTitleStyle,
            fontSize: '8rem',
            position: 'relative',
            left: '3px',
            border: 'none',
          }}
        >
          Q
        </Typography>
        uota
      </Typography>
      <Typography sx={appSubtitleStyle}>a game of quotes</Typography>
      <Typography sx={versionStyle}>alpha 0.1</Typography>
    </Box>
  )
}

export default GameLogo
