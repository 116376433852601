import { Fade } from '@mui/material'
import { useEffect, useState } from 'react'
import Delayed from './delayed'
import { isObject } from '../../lib/utils'

const DelayedFade = (props) => {
  const {
    appearDelay = 250,
    fadeoutAfter = 0,
    fadeSpeed = 250,
    onEntering = () => {},
    onEntered = () => {},
  } = props
  let fadeInSpeed = fadeSpeed
  let fadeoutSpeed = fadeoutAfter ? fadeSpeed : 0
  if (isObject(fadeSpeed)) {
    fadeInSpeed = fadeSpeed.in
    fadeoutSpeed = fadeSpeed.out
  }

  const [show, setShow] = useState(false)

  useEffect(() => {
    let timer1, timer2
    timer1 = setTimeout(() => {
      setShow(true)
    }, appearDelay)

    if (fadeoutAfter) {
      timer2 = setTimeout(() => {
        setShow(false)
      }, appearDelay + fadeoutAfter)
    }
    return () => {
      if (timer1) clearTimeout(timer1)
      if (timer2) clearTimeout(timer2)
    }
  }, [appearDelay, fadeoutAfter])

  return (
    <Delayed waitBeforeShow={appearDelay}>
      <Fade
        in={show}
        timeout={{ enter: fadeInSpeed, exit: fadeoutSpeed }}
        onEntering={onEntering}
        onEntered={onEntered}
      >
        {props.children}
      </Fade>
    </Delayed>
  )
}

export default DelayedFade
