import { Typography, Button } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import {
  ScienceOutlined as Lab,
  HomeOutlined as Home,
  HorizontalSplitOutlined as Packs,
  SettingsOutlined as Settings,
  HelpOutlineOutlined as Help,
} from '@mui/icons-material'

const BottomBarButton = (props) => {
  const { path } = props
  const navigate = useNavigate()
  const location = useLocation().pathname.replace('/', '')
  const selected = path === location
  const color = selected ? 'highlight' : 'secondary'

  const getLabel = () => {
    switch (path) {
      case 'lab':
        return 'the lab'
      default:
        return path
    }
  }

  const getIcon = () => {
    switch (path) {
      case 'home':
        return <Home fontSize={'inherit'} color={color} />
      case 'lab':
        return <Lab fontSize={'inherit'} color={color} />
      case 'packs':
        return <Packs fontSize={'inherit'} color={color} />
      case 'settings':
        return <Settings fontSize={'inherit'} color={color} />
      case 'help':
        return <Help fontSize={'inherit'} color={color} />
      default:
        throw new Error()
    }
  }

  const iconStyle = {
    height: '80%',
    aspectRatio: '1/1',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'none',
    position: 'relative',
    top: '0px',
    fontSize: ['2rem', '2.3rem', '2.5rem'],
  }

  const labelStyle = {
    color: `${color}.main`,
    lineHeight: 1.1,
    whiteSpace: 'nowrap',
    fontFamily: 'Special Elite',
  }

  const navigateHandler = () => {
    navigate(`/${path}`) // todo: broken for localization
  }

  return (
    <Button onClick={navigateHandler} sx={iconStyle}>
      {getIcon()}
      <Typography sx={labelStyle}>{getLabel()}</Typography>
    </Button>
  )
}

export default BottomBarButton
