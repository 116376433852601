import { Box, Typography } from '@mui/material'
import { CheckOutlined, LockOutlined } from '@mui/icons-material'

const boxStyle = {
  ml: 'auto',
  '& *': { height: '20px', verticalAlign: 'bottom' },
}

const ChapterStatus = (props) => {
  return (
    <Box sx={boxStyle}>
      {props.isLocked ? (
        <LockOutlined />
      ) : props.isCompleted ? (
        <CheckOutlined />
      ) : (
        <Typography>{props.remaining}</Typography>
      )}
    </Box>
  )
}

export default ChapterStatus
