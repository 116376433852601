import { writeDoc, logEvent as logEventFirebase } from './firebase'
import * as aws from './aws'
import { isDevTest } from '../lib/utils'

let root
const logLocalHost = false

export const setStore = (rootStore) => {
  root = rootStore
}

const addCommonFields = (obj) => {
  let o = {
    ...obj,
    timestamp: new Date().toISOString(),
    gamever: root.gameStore.gameVersion,
    user: root.gameStore.uuid,
    site: window.location.href,
  }

  if (root.puzzle) {
    o = {
      ...o,
      puzzleId: root.puzzle.id,
    }
  }

  if (root.puzzleStore.chapter) {
    o = {
      ...o,
      chapterId: root.puzzleStore.chapter.id,
    }
  }

  return o
}

export const logEvent = async (eventName, eventObj = {}) => {
  if (!logLocalHost && isDevTest()) {
    return
  }

  const p = addCommonFields(eventObj)
  logEventFirebase(eventName, p)
}

export const logToDb = async (collection, obj) => {
  if (!logLocalHost && isDevTest()) {
    return
  }

  const p = addCommonFields(obj)
  await writeDoc(collection, p)
}

// todo: switch this over to firebase
export const logAppError = async (obj) => {
  if (isDevTest()) {
    return
  }

  const p = addCommonFields(obj)
  await aws.logErrorEvent(p)
}

export const writeTimeToCloud = async (obj, addCommon = true) => {
  if (isDevTest()) {
    return
  }
  const p = addCommon ? addCommonFields(obj) : obj
  await writeDoc('gp_results', p)
}

export const writeRatingToCloud = async (obj, addCommon = true) => {
  if (isDevTest()) {
    return
  }
  const p = addCommon ? addCommonFields(obj) : obj
  await writeDoc('puzzle_rating', p)
}

export const key = {
  feedback_question: 'feedback_question',
  feedback_form: 'feedback_form',
  chapter_started: 'chapter_started',
  chapter_ended: 'chapter_ended',
  attrib_link: 'attrib_link',
  tutorial_begin: 'tutorial_begin',
  tutorial_complete: 'tutorial_complete',
  level_start: 'level_start',
  level_end: 'level_end',
  puzzle_skip: 'puzzle_skip',
  show_hint: 'show_hint',
  app_started: 'app_started',
  puzzle_failed: 'puzzle_failed',
  settings: 'settings',
  chromeSplashHack: 'chrome_splash_hack',
  pack_click: 'pack_click',
}
