// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent as gaLogEvent } from 'firebase/analytics'
import {
  getFirestore,
  connectFirestoreEmulator,
  collection,
  addDoc,
  doc,
  getDoc,
  serverTimestamp,
} from 'firebase/firestore'
import { isDevTest } from '../lib/utils'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCEfMq8AuvRRKljQq5oGgrbTeR8o5fXCe8',
  authDomain: 'swap-b7d41.firebaseapp.com',
  projectId: 'swap-b7d41',
  storageBucket: 'swap-b7d41.appspot.com',
  messagingSenderId: '567845549205',
  appId: '1:567845549205:web:8cd920f90428dbf981d79c',
  measurementId: 'G-W42SKKZSQP',
}

const isLocalHost = () => {
  const host = window.location.hostname
  return host.match('localhost')
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

let analytics
if (!isDevTest()) {
  analytics = getAnalytics(app)
}

if (isLocalHost()) {
  //  connectFirestoreEmulator(db, '127.0.0.1', 8080)
}

export const logEvent = (eventName, eventObj) => {
  analytics && gaLogEvent(analytics, eventName, eventObj)
}

export const writeDoc = async (collectionPath, doc) => {
  doc = {
    ...doc,
    ...{ createdAt: serverTimestamp() },
  }
  try {
    const docRef = await addDoc(collection(db, collectionPath), doc)
    console.log('Document written with ID: ', docRef.id)
  } catch (e) {
    console.error('Error adding document: ', e)
  }
}

export const getStatsDoc = async (puzzleId) => {
  try {
    const docRef = doc(db, 'gp_stats', puzzleId)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      return docSnap.data()
    } else {
      return 'not found'
    }
  } catch (e) {
    return 'error'
  }
}
