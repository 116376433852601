import { Box, Button } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import BottomBar from '../bottomBar.js'
import GameLogo from '../gameLogo'

const HomePage = (props) => {
  const buttonContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    mt: [5, 7, 8],
    width: '80%',
    maxWidth: '300px',
  }

  const buttonStyle = {
    fontWeight: 600,
    borderRadius: '3px',
    fontSize: ['2rem', '2.5rem', '2.5rem'],
    textTransform: 'none',
    color: 'primary.main',
    lineHeight: 1.5,
  }

  return (
    <Box
      id='homePage'
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <GameLogo />

      <Box id='buttonContainer' sx={buttonContainerStyle}>
        <Button
          sx={{ ...buttonStyle, ...{ color: 'highlight.main' } }}
          variant='outlined'
          onClick={() => {}}
          fullWidth={true}
          size='small'
          component={RouterLink}
          to={'/daily'}
        >
          <span style={{ textTransform: 'none' }}>play daily</span>
        </Button>{' '}
        <Button
          sx={buttonStyle}
          variant='outlined'
          onClick={() => {}}
          fullWidth={true}
          size='small'
          component={RouterLink}
          to={'/packs'}
        >
          more puzzles
        </Button>
      </Box>
      <BottomBar />
    </Box>
  )
}

export default HomePage
