import { makeAutoObservable, observable } from 'mobx'
import { makePersistable, clearPersistedStore } from 'mobx-persist-store'
import { logEvent, key } from '../service/analytics'

export class Chapter {
  root
  id
  name
  subtitle
  difficulty
  category
  sequence
  completedPuzzles

  constructor(data, rootStore) {
    this.root = rootStore
    this.id = data.id
    this.name = data.name
    this.subtitle = data.subtitle || 'this is subtitle'
    this.difficulty = data.difficulty
    this.category = data.category

    this.sequence = data.sequence.map((v) => String(v))
    this.completedPuzzles = []

    makeAutoObservable(this, {
      completedPuzzles: observable.shallow,
      isLocked: false,
    })

    makePersistable(this, {
      name: `chapter-${this.id}`,
      properties: ['completedPuzzles'],
      storage: window.localStorage,
    })
  }

  prepPlay() {
    if (this.id === 'intro' || this.isCompleted) {
      this.resetChapterProgress()
    }
  }

  async clearStorage() {
    await clearPersistedStore(this)
  }

  get isLocked() {
    // temp hack
    return this.sequence.length === 0
  }

  resetChapterProgress() {
    this.completedPuzzles = []
  }

  get isCompleted() {
    return this.completedPuzzles.length >= this.sequence.length
  }

  get cursor() {
    let i = 0
    for (; i < this.sequence.length; i++) {
      const candidate = this.sequence[i]
      if (!this.completedPuzzles.includes(candidate)) {
        break
      }
    }
    return i
  }

  registerCompletedPuzzle(id) {
    if (!this.completedPuzzles.includes(id)) {
      this.completedPuzzles.push(id)
    }

    if (this.isCompleted) {
      logEvent(key.chapter_ended, {
        chapterId: this.id,
      })
    }

    if (id.match('^d-')) {
      this.root.puzzleStore.updatePlayedDailies(id)
    }
  }

  get remainingPuzzlesLabelShort() {
    return `${this.completedPuzzles.length} / ${this.sequence.length}`
  }

  get remainingPuzzlesLabel() {
    return `${this.sequence.length - this.completedPuzzles.length} of ${
      this.sequence.length
    } puzzles left`
  }
}
