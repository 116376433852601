import {
  getRect,
  areRectsOverlapped,
  addAndRemoveClassWithEndCallback,
} from '../../lib/utils'

const initialRadius = 30
const incrementalRadius = 10
const maxIterations = 30
let rects
let angles
let wordbankCenter
let wordbank
let wordbankRect
let iterationCount
let gap

let root = null
let callbacks = null

export const init = (rootStore, listOfCallbacks) => {
  root = rootStore
  callbacks = listOfCallbacks

  wordbank = document.getElementById('wordbank')
  wordbank.innerHTML = ''
  for (const bankItem of root.puzzle.wordBank) {
    const wordSpan = document.createElement('span')
    wordSpan.classList.add('draggable')
    wordSpan.append(document.createTextNode(bankItem.text))
    wordSpan.append(document.createTextNode(' '))
    wordbank.append(wordSpan)
    bankItem.elemPtr = wordSpan

    const xPad = bankItem.text.length <= 3 ? 16 : 8
    wordSpan.style.paddingLeft = `${xPad}px`
    wordSpan.style.paddingRight = `${xPad}px`
  }

  setTimeout(distributeWords, 50)
}

export function syncWordBank() {
  const wordbank = document.getElementById('wordbank')
  for (const child of wordbank.children) {
    if (!root.puzzle.wordBank.find((bankItem) => bankItem.elemPtr === child)) {
      child.remove()
    }
  }
}

export const distributeWords = () => {
  wordbankRect = getRect(wordbank)
  wordbankCenter = { x: wordbankRect.width / 2, y: wordbankRect.height / 2 }

  rects = []
  angles = [0]
  iterationCount = 0
  gap = getGap()
  initWordStyles()
  setupRects()

  setInitialPositions()
  calcSpreadoutPositions()
  centerAndScalePositions()
  setTimeout(animateToFinalPositions, 500)
}

const animateToFinalPositions = () => {
  for (let i = 0; i < wordbank.children.length; i++) {
    const child = wordbank.children[i]
    child.style.left = `${rects[i].x}px`
    child.style.top = `${rects[i].y}px`
    child.style.opacity = 1
    addAndRemoveClassWithEndCallback(
      child,
      'firstAppearancePositionTransition',
      'transitionend',
      i === 0 ? finalize : null
    )
  }
}

const finalize = () => {
  callbacks.refreshTextPresentation()
}

const centerRectOnPoint = (p, rect) => {
  rect.x = wordbankCenter.x + p.x - rect.width / 2
  rect.y = wordbankCenter.y + p.y - rect.height / 2
}

const setInitialRectPosByAngleAndRadius = (rect, angle, radius) => {
  const pt = {
    x: Math.cos(angle) * radius,
    y: Math.sin(angle) * radius,
  }
  centerRectOnPoint(pt, rect)
}

const updateRectByAngleAndRadius = (rect, angle, radius) => {
  const pt = {
    x: Math.cos(angle) * radius,
    y: Math.sin(angle) * radius,
  }
  rect.x = rect.x + pt.x
  rect.y = rect.y + pt.y
}

const setInitialPositions = () => {
  const angleBetweenPoints =
    rects.length > 1 ? (Math.PI * 2) / (rects.length - 1) : 0

  let startAngle = Math.PI / -2
  if (rects.length === 2) {
    startAngle = Math.PI / 2
  }
  let currentAngle = startAngle

  for (let i = 0; i < rects.length; i++) {
    if (i === 0) {
      centerRectOnPoint({ x: 0, y: 0 }, rects[i])
    } else {
      setInitialRectPosByAngleAndRadius(rects[i], currentAngle, initialRadius)
      angles.push(currentAngle)
      currentAngle += angleBetweenPoints
    }
  }
}

const calcSpreadoutPositions = () => {
  let rectsToMoveOut
  let testedPairIds
  let skipped

  do {
    rectsToMoveOut = []
    testedPairIds = []
    for (let i = 0; i < rects.length; i++) {
      for (let j = 0; j < rects.length; j++) {
        if (i === j) continue
        const sorted = [i, j].sort()
        const pairId = `${sorted[0]}-${sorted[1]}`
        if (testedPairIds.includes(pairId)) continue
        testedPairIds.push(pairId)

        if (areRectsOverlapped(rects[i], rects[j], gap)) {
          let indexToMove

          if (i === 0) indexToMove = j
          else if (j === 0) indexToMove = i
          else indexToMove = getLongerWordIndex(i, j)

          if (!rectsToMoveOut.includes(indexToMove)) {
            rectsToMoveOut.push(indexToMove)
          }
        }
      }
    }

    skipped = []
    for (let i = 0; i < rectsToMoveOut.length; i++) {
      if (!false /* is at top or bottom*/) {
        const indexToMove = rectsToMoveOut[i]
        const angle = angles[indexToMove]
        updateRectByAngleAndRadius(rects[indexToMove], angle, incrementalRadius)
      } else {
        skipped.push(i)
      }
    }
    iterationCount++
  } while (
    rectsToMoveOut.length - skipped.length > 0 &&
    iterationCount < maxIterations
  )
}

const shiftRects = (x, y) => {
  for (const rect of rects) {
    rect.x += x
    rect.y += y
  }
}

const centerAndScalePositions = () => {
  let minLeft = 1000
  let maxRight = -1000
  let minTop = 1000
  let maxBottom = -1000
  for (const rect of rects) {
    if (rect.left < minLeft) minLeft = rect.left
    if (rect.right > maxRight) maxRight = rect.right
    if (rect.top < minTop) minTop = rect.top
    if (rect.bottom > maxBottom) maxBottom = rect.bottom
  }
  const shiftX = wordbankCenter.x - (minLeft + maxRight) / 2
  let shiftY
  if (maxBottom - minTop > wordbankRect.height) {
    shiftY = wordbankCenter.y - (minTop + maxBottom) / 2
  } else {
    shiftY = -minTop - 20
  }
  shiftRects(shiftX, shiftY)
  scalePositions(maxRight - minLeft, maxBottom - minTop)
}

const scalePositions = (spreadX, spreadY) => {
  const maxAmountToShrinkX = (spreadX - wordbankRect.width) / 2
  const maxAmountToShrinkY = (spreadY - wordbankRect.height) / 2
  for (const rect of rects) {
    const distanceFromCenterX = rect.left + rect.width / 2 - wordbankCenter.x
    const ratioX =
      distanceFromCenterX / (maxAmountToShrinkX + wordbankRect.width / 2)
    rect.x -= maxAmountToShrinkX * ratioX
    if (maxAmountToShrinkY > 0) {
      const distanceFromCenterY = rect.top + rect.height / 2 - wordbankCenter.y
      const ratioY =
        distanceFromCenterY / (maxAmountToShrinkY + wordbankRect.height / 2)
      rect.y -= maxAmountToShrinkY * ratioY
    }
  }
}

const getLongerWordIndex = (a, b) => {
  return wordbank.children[a].textContent.length >
    wordbank.children[b].textContent.length
    ? b
    : a
}

const setupRects = () => {
  for (let i = 0; i < wordbank.children.length; i++) {
    const boundingRect = wordbank.children[i].getBoundingClientRect()
    rects.push(boundingRect)
  }
}

function initWordStyles() {
  const wordbankRect = getRect(wordbank)
  for (let i = 0; i < wordbank.children.length; i++) {
    const child = wordbank.children[i]
    child.style.position = 'absolute'
    const xPad = child.textContent.trim().length <= 3 ? 16 : 8
    child.style.paddingLeft = `${xPad}px`
    child.style.paddingRight = `${xPad}px`
    const widthOffset = child.getBoundingClientRect().width / 2
    child.style.left = `${wordbankRect.width / 2 - widthOffset}px`
    child.style.top = '50%'
    child.style.opacity = 0
    // child.style.outline = '1px solid blue'
  }
}

const getGap = () => {
  const textLen = wordbank.textContent.length
  return { x: 0, y: -5 }
  if (textLen > 100) {
    return { x: 0, y: 0 }
  } else if (textLen > 70) {
    return { x: 0, y: 0 }
  } else {
    return { x: 0, y: 0 }
  }
}
