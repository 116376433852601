import React, { useRef, useContext } from 'react'
// import { observer } from 'mobx-react-lite'
import { Box, Dialog, DialogTitle, Typography, Button } from '@mui/material'
import { RootStoreContext } from '../store/rootStoreContext'

const StatsShareDialog = (props) => {
  const [root] = useContext(RootStoreContext)
  const textRef = useRef(null)
  const { open, continueAction } = props

  const dialogStyle = {
    minWidth: ['80%', '50%', '50%'],
    px: 2,
    py: 1,
    backgroundColor: 'quoteCardBackground',
    borderRadius: '5px',
  }

  const textStyle = {
    mx: 'auto',
    lineHeight: 1.2,
    borderColor: 'secondary.main',
    borderWidth: 1,
    borderStyle: 'dotted',
    p: 2,
    userSelect: 'text',
    WebkitUserSelect: 'text',
    msUserSelect: 'text',
  }

  const buttonStyle = {
    textTransform: 'none',
    mx: 'auto',
    mt: 3,
  }

  const copyToClipboard = async () => {
    const text = textRef.current.textContent
    const type = 'text/plain'
    const blob = new Blob([text], { type })

    try {
      const cbi = new ClipboardItem({
        [type]: blob,
      })

      await navigator.clipboard.write([cbi])
      continueAction('succeeded')
    } catch {
      continueAction('failed')
    }
  }

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={'sm'}
      onClose={() => {
        continueAction()
      }}
    >
      <Box sx={dialogStyle}>
        <Box sx={{ display: 'flex', flexDirection: 'column', py: 2 }}>
          <Typography sx={textStyle} ref={textRef}>
            I just scored {root.puzzle.stats.communityRankLabel} on today's
            #DailyQuota! See if you can beat my score: https://quotapuzzle.com
          </Typography>
          <Button
            variant='outlined'
            sx={buttonStyle}
            onClick={async () => {
              await copyToClipboard()
            }}
          >
            copy to clipboard
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

export default StatsShareDialog
