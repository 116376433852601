import { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { Box, Typography } from '@mui/material'
import { StarOutlined } from '@mui/icons-material'
import { RootStoreContext } from '../store/rootStoreContext'
import { timeDisplayFromSeconds } from '../lib/utils'
import Starburst from './starburst'

const DistributionChart = (props) => {
  const [root] = useContext(RootStoreContext)

  const Bar = (props) => {
    const { data, firstBarTime, lastBarTime, fullHeight, maxShare, isUserBar } =
      props

    const heightPerUnitShare = fullHeight / maxShare

    const barStyle = {
      overflow: 'hidden',
      display: 'inline-block',
      backgroundColor: isUserBar ? 'highlight.main' : 'secondary.tinted',
      height: `${heightPerUnitShare * data.share}px`,
      width: '100%',
      lineHeight: 1,
    }

    const labelStyle = {
      color: 'secondary.main',
      fontWeight: 400,
      position: 'absolute',
      top: '-2px',
      textAlign: 'start',
      transform: 'rotate(45deg)',
      transformOrigin: 'bottom left',
    }

    const starStyle = {
      position: 'absolute',
      left: '-2px',
      bottom: '2px',
    }

    const lineStyle = {
      width: '2px',
      height: `${fullHeight + 6}px`,
      overflow: 'hidden',
      backgroundColor: 'highlight.main',
      position: 'relative',
      left: '7px',
      top: '-2px',
    }

    const dotStyle = {
      width: '12px',
      height: '4px',
      background: (theme) => theme.palette.highlight.contrast,
      position: 'absolute',
      left: '2px',
      bottom: `${
        Math.floor(
          heightPerUnitShare *
            data.share *
            root.puzzle.stats.communityRank.ratioWithinBar
        ) - 1
      }px`,
      opacity: heightPerUnitShare * data.share > 20 ? 1 : 0,
    }

    return (
      <Box
        sx={{
          lineHeight: 0.85,
          minWidth: '12px',
          mr: '2px',
          position: 'relative',
        }}
      >
        <Box sx={barStyle}>&nbsp;</Box>
        <Box sx={{ position: 'relative' }}>
          {firstBarTime && (
            <Box sx={labelStyle}>
              {timeDisplayFromSeconds(Math.floor(firstBarTime))}
            </Box>
          )}
          {lastBarTime && (
            <Box sx={labelStyle}>
              {timeDisplayFromSeconds(Math.floor(lastBarTime)) + '+'}
            </Box>
          )}
        </Box>
        {isUserBar && (
          <Box sx={starStyle}>
            <StarOutlined
              color='highlight'
              sx={{
                fontSize: '1.2rem',
                position: 'relative',
                left: '-1.5px',
                top: '6px',
              }}
            />
            <Box sx={lineStyle}>&nbsp;</Box>
            <Box sx={dotStyle} />
          </Box>
        )}
      </Box>
    )
  }

  const containerStyle = {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    mt: 3,
  }

  const barContainerStyle = {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    mx: 'auto',
  }

  const starburstStyle = {
    position: 'absolute',
    top: '-80px',
    ...(root.puzzle.stats.isTopHalf ? { right: '-20px' } : { left: '-20px' }),
  }

  const fullHeight = 90

  const baseLine = {
    position: 'absolute',
    height: '2px',
    width: '278px',
    top: `${fullHeight}px`,
    backgroundColor: 'secondary.main',
  }

  const maxShare = root.puzzle.stats.data.bars.reduce(
    (a, c) => Math.max(a, c.share),
    0
  )

  return (
    <Box sx={containerStyle} id='chart'>
      <Box sx={barContainerStyle}>
        {root.puzzle.stats.data.bars.map((data, i) => (
          <Bar
            key={i}
            data={data}
            firstBarTime={i === 0 && root.puzzle.stats.data.minTime}
            lastBarTime={
              i === root.puzzle.stats.data.bars.length - 1 &&
              root.puzzle.stats.data.maxTime
            }
            fullHeight={fullHeight}
            maxShare={maxShare}
            isUserBar={i === root.puzzle.stats.playerBarId}
          />
        ))}
        <Box sx={baseLine} id='baseline' />
        <Starburst
          text={root.puzzle.stats.communityRankLabel}
          sx={starburstStyle}
          angle={15 * (root.puzzle.stats.isTopHalf ? 1 : -1)}
        />
      </Box>
    </Box>
  )
}

export default observer(DistributionChart)
