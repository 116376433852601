import soundsWebm from '../assets/sounds/sounds.webm'
import soundsMp3 from '../assets/sounds/sounds.mp3'

import { Howl } from 'howler'

let sounds
let isInitialized = false
let isSoundEnabled = false

export function init() {
  if (!isInitialized) {
    isInitialized = false

    sounds = new Howl({
      src: [soundsWebm, soundsMp3],
      sprite: {
        dragClick: [0, 16.417233560090704],
        //        coins: [2000, 382.1541950113376],
        failClick: [4000, 108.70748299319732],
        successDing: [6000, 50.24943310657637],
      },
    })
  }
}

export function setIsSoundEnabled(v) {
  isSoundEnabled = v
}

export function play(clipName) {
  if (isSoundEnabled) {
    sounds.play(clipName)
  }
}
