import * as hitTest from './hitTest'
import { removeAndAddListener, removeListener, getRect } from '../../lib/utils'

let root = null
let callbacks = null

let wordbankRect
let selectedEl = null
let draggedEl = null
let draggedElStartRect = null
let dragDestX
let dragDestY
let dropTargetedBlank = null

export const init = (rootStore, listOfCallbacks) => {
  root = rootStore
  callbacks = listOfCallbacks
  hitTest.init(root)
  setupEventHandlers()
}

export const updateCache = () => {
  hitTest.updateCaches()
}

const setupEventHandlers = () => {
  removeAndAddListener('wordbank', 'pointerup', onWordBankUp)
  removeAndAddListener('wordbank', 'pointerdown', onWordBankDown)
  removeAndAddListener('puzzle', 'pointerup', onPuzzleUp)
  removeAndAddListener('sentence', 'pointerup', onSentencePointerUp)
  removeAndAddListener('sentence', 'pointerleave', onSentencePointerLeave)
  removeAndAddListener('wordbank', 'touchstart', onWordbankTouchStart)
}

const onWordBankDown = (ev) => {
  wordbankRect = getRect(document.getElementById('wordbank'))
  if (ev.isPrimary === false) {
    return
  }

  const downPoint = { x: ev.x, y: ev.y }
  const index = hitTest.findClosestBankWord(downPoint)
  if (index >= 0) {
    selectedEl = root.puzzle.wordBank[index].elemPtr
    selectedEl.initialLeft = selectedEl.offsetLeft
    selectedEl.initialTop = selectedEl.offsetTop
    removeAndAddListener('sentence', 'pointermove', dragMove)
  }
  ev.preventDefault()
}

const onWordBankUp = (ev) => {
  if (ev.isPrimary === false) {
    return
  }

  const index = hitTest.findClosestBankWord({ x: ev.x, y: ev.y })
  if (index >= 0 && !draggedEl) {
    const bankWordEl = root.puzzle.wordBank[index].elemPtr
    const blankEl = callbacks.getSelectedBlankEl()
    if (blankEl) {
      callbacks.sendWordToBlank(bankWordEl, blankEl, () => {
        const wasCorrect = callbacks.wordBankWordTapped(bankWordEl)
        if (!wasCorrect) {
          setTimeout(() => callbacks.sendWordBackToBank(bankWordEl), 0)
        }
      })
    } else {
      callbacks.wordBankWordTapped(bankWordEl)
    }
  } else {
    callbacks.clearSelection(null)
  }

  dragEnd(ev)
  ev.preventDefault()
}

const onPuzzleUp = (ev) => {
  if (ev.isPrimary === false) {
    return
  }

  if (draggedEl) {
    dragEnd(ev)
    return
  }

  const index = hitTest.findClosestBlank({ x: ev.x, y: ev.y })
  const blankEl = index === -1 ? null : root.puzzle.wordList[index].elemPtr
  if (blankEl && blankEl.classList.contains('incorrect')) {
    const bankWordEl = callbacks.getSelectedWordEl()
    if (bankWordEl) {
      callbacks.sendWordToBlank(bankWordEl, blankEl, () => {
        const wasCorrect = callbacks.puzzleBlankTapped(blankEl)
        if (!wasCorrect) {
          setTimeout(() => callbacks.sendWordBackToBank(bankWordEl), 0)
        }
      })
    } else {
      callbacks.puzzleBlankTapped(blankEl)
    }
  }

  ev.preventDefault()
}

const onSentencePointerUp = (ev) => {
  if (draggedEl) {
    dragEnd(ev)
  }
}

const onSentencePointerLeave = (ev) => {
  if (draggedEl) {
    dragEnd(ev)
  }
}

const onWordbankTouchStart = (ev) => {
  // hack to prevent haptic feedback on long touch on android
  ev.preventDefault()
  ev.stopPropagation()
}

const dragHasStarted = (ev) => {
  draggedEl = selectedEl
  draggedEl.classList.add('selected')

  draggedElStartRect = getRect(draggedEl)
  draggedEl.pointerOffsetX = ev.clientX - draggedElStartRect.x
  draggedEl.pointerOffsetY = ev.clientY - draggedElStartRect.y
  updateDragDestFromPointerEvent(ev)
  //sound.play('dragClick')
  window.requestAnimationFrame(dragStep)
  callbacks.clearSelection()
}

const dragStep = (timestamp) => {
  if (!draggedEl) {
    return
  }

  const dx = dragDestX - draggedEl.offsetLeft
  const dy = dragDestY - draggedEl.offsetTop
  draggedEl.style.left = `${draggedEl.offsetLeft + dx}px`
  draggedEl.style.top = `${draggedEl.offsetTop + dy}px`
  hitTest.updateWordBankCacheItem(draggedEl)
  window.requestAnimationFrame(dragStep)
}

const dragMove = (ev) => {
  if (ev.isPrimary === false) {
    return
  }

  if (!draggedEl) {
    dragHasStarted(ev)
    return
  }

  const vPointer = { x: ev.x, y: ev.y }
  const blank = getClosestIncorrectBlank(vPointer)
  if (blank) {
    doSnap(blank)
  } else {
    updateDragDestFromPointerEvent(ev)
    doUnSnap(ev)
  }
  callbacks.updateDropTarget(blank)
}

const getClosestIncorrectBlank = (p) => {
  const index = hitTest.findClosestBlank(p, 1000)
  let elem
  elem = index >= 0 ? root.puzzle.wordList[index].elemPtr : null
  if (elem && !elem.classList.contains('incorrect')) {
    elem = null
  }

  return elem
}

const doSnap = (blank) => {
  dropTargetedBlank = blank
  const dropTargetedBlankRect = getRect(dropTargetedBlank)
  dragDestX =
    dropTargetedBlankRect.x +
    dropTargetedBlankRect.width / 2 -
    wordbankRect.left -
    draggedElStartRect.width / 2
  dragDestY = dropTargetedBlankRect.y - wordbankRect.top - 11
  const scaleRatio = Math.min(
    1,
    0.1 + dropTargetedBlankRect.width / (draggedElStartRect.width - 16)
  )
  draggedEl.style.transform = `scale(${scaleRatio})`
  draggedEl.style.transformOrigin = 'center'
}

const doUnSnap = (ev) => {
  draggedEl.style.transform = ''
  draggedEl.style.transformOrigin = ''
  dropTargetedBlank = null
}

const updateDragDestFromPointerEvent = (ev) => {
  dragDestX = ev.clientX - wordbankRect.left - draggedEl.pointerOffsetX
  dragDestY = ev.clientY - wordbankRect.top - draggedEl.pointerOffsetY
}

const dragEnd = (ev) => {
  removeListener('sentence', 'pointermove', dragMove)
  callbacks.updateDropTarget(null)
  if (!draggedEl) {
    return
  }

  const puzzleRect = getRect(document.getElementById('puzzle'))
  const outOfBounds =
    ev.x < puzzleRect.left || ev.x > puzzleRect.right || ev.y < puzzleRect.top
  if (dropTargetedBlank && !outOfBounds) {
    callbacks.setOverlayVisibility(dropTargetedBlank, false)
    const wasCorrect = callbacks.attemptToPlaceWord(
      draggedEl,
      dropTargetedBlank
    )
    if (wasCorrect) {
      draggedEl.remove()
    } else {
      callbacks.sendWordBackToBank(draggedEl)
      callbacks.setOverlayVisibility(dropTargetedBlank, false)
    }
  } else {
    const dropTargets = document.elementsFromPoint(ev.x, ev.y)
    if (dropTargets.includes(document.getElementById('wordbank'))) {
      hitTest.updateCaches()
    } else {
      callbacks.sendWordBackToBank(draggedEl)
    }
  }
  // todo: i think tapping outside of sentence should clear selection as well
  // todo: safety net, never translate outside the bounds of the page.

  draggedEl && draggedEl.classList.remove('selected')
  draggedEl = null
  dropTargetedBlank = null
  ev.preventDefault()
}
