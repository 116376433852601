import { makeAutoObservable } from 'mobx'
import { clearPersistedStore } from 'mobx-persist-store'
import { PuzzleStore } from './puzzleStore'
import { GameStore } from './gameStore'
import { HelpTips } from './HelpTips'
import { logEvent, key } from '../service/analytics'

export class RootStore {
  puzzleStore
  gameStore
  helpTips

  puzzle = null

  constructor() {
    this.puzzleStore = new PuzzleStore(this)
    this.gameStore = new GameStore(this)
    this.helpTips = new HelpTips(this)

    makeAutoObservable(this, {})

    logEvent(key.app_started)
  }

  async clearStoredData() {
    await clearPersistedStore(this.helpTips)
    await clearPersistedStore(this.puzzleStore)
    await clearPersistedStore(this.gameStore)
    await this.puzzleStore.clearStoredData()
  }

  setPuzzle(obj) {
    this.puzzle = obj
    if (!obj) {
      this.helpTips.stopTracking()
    }
  }
}
