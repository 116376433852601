import { Box, Typography } from '@mui/material'
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useContext } from 'react'
import { RootStoreContext } from '../store/rootStoreContext.js'
import { timeDisplayFromSeconds } from '../lib/utils'
import DelayedFade from './helper/delayedFade'

const TimerBar = (props) => {
  const [root] = useContext(RootStoreContext)

  const textStyle = {
    fontSize: ['1.2rem', '1.4rem', '1.6rem'],
    fontWeight: 700,
    color: root.gameStore.prefersTimeShown
      ? 'highlight.main'
      : 'secondary.main',
  }

  const penaltyStyle = {
    color: 'error.main',
    textAlign: 'center',
    position: 'absolute',
    right: '100px',
    top: '7px',
    fontSize: ['1.2rem', '1.4rem', '1.6rem'],
    fontWeight: 800,
  }

  const iconStyle = {
    fontSize: '1.1rem',
    color: 'secondary.main',
  }

  const toggleTimerVisibility = () => {
    root.gameStore.setPrefersTimeShown(!root.gameStore.prefersTimeShown)
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        gap: 1,
        mr: '-4px',
        width: '100%',
      }}
    >
      {/* todo: move this to a standalone component */}
      {root.puzzle.penaltySeconds > 0 && root.gameStore.prefersTimeShown && (
        <DelayedFade appearDelay={0} fadeoutAfter={1000}>
          <Box sx={penaltyStyle}>+{root.puzzle.penaltySeconds}s</Box>
        </DelayedFade>
      )}
      <IconButton {...props} color='inherit' onClick={toggleTimerVisibility}>
        <Typography sx={textStyle}>
          {root.gameStore.prefersTimeShown
            ? timeDisplayFromSeconds(Math.floor(root.puzzle.secondsTaken))
            : '-:--'}
        </Typography>
        &nbsp;
        {root.gameStore.prefersTimeShown ? (
          <VisibilityOffOutlined sx={iconStyle} />
        ) : (
          <VisibilityOutlined sx={iconStyle} />
        )}
      </IconButton>
    </Box>
  )
}

export default observer(TimerBar)
