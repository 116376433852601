import { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { Box, Typography } from '@mui/material'
import { RootStoreContext } from '../store/rootStoreContext'

const Coins = (props) => {
  const [root] = useContext(RootStoreContext)

  const textStyle = {
    fontWeight: 800,
    color: 'gold',
  }

  return (
    <Box>
      <Typography component='span' sx={{ ...textStyle, ...props.sx }}>
        o
      </Typography>
      <Typography component='span' sx={textStyle}>
        {root.gameStore.purseBalance}
      </Typography>
    </Box>
  )
}

export default observer(Coins)
