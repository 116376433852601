import { Box, Typography } from '@mui/material'
import { useContext } from 'react'
import { RootStoreContext } from '../../store/rootStoreContext'
import BottomBar from '../bottomBar'
import FeedbackLink from '../feedbackLink'

const LabPage = (props) => {
  const [root] = useContext(RootStoreContext)

  return (
    <Box
      id='labPage'
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'start',
        textAlign: 'left',
        pt: 12,
        px: 6,
      }}
    >
      <Typography variant='h4' sx={{ fontFamily: 'Special Elite', mb: 3 }}>
        The Lab
      </Typography>
      <Typography>
        Once complete, the Lab will be an area for testing out upcoming content,
        as well as a place users can create and share their own puzzles ideas.
        Stay tuned for more!
        <br />
        <br />
        Meanwhile if you'd like to suggest a quote,{' '}
        <FeedbackLink linkText='send it here!' />
      </Typography>
      <BottomBar />
    </Box>
  )
}

export default LabPage
